import { SrvRecord } from "dns";
import { ReactNode } from "react";
import { AdvertizeMode } from "./config/IMobileAppSettings";

export type Guid = string;

export type ILocalString = { [lang: string]: string }

export type LocalString = ILocalString | { (args: LocalString[]) : ReactNode } | string;

export type JsonDate = string | Date;

export enum Nts100EventCategory {
    Normal,
    SubFree,
    SubFixed
}

export enum MapFileType {
    Map4Project = 1,
    Map4View = 2,
    SvgMaster = 3,
    Folder = 4,
    Preview = 5,
    Custom = 10
}

export enum EventViewField {
    Tickets,
    Description,
    Venue,
    Movie,
    Availability,
    PriceList,
    Sectors,
    Category
}

export interface IPriceListItem {
    priceListId: number;
    reducedId?: number;
    name: string;
    personalDataFormId: string;
}

export interface IPersonalDataOption {
    value: string;
    text: string;
}

export enum PersonalDataRecordType {
    Text,
    Boolean,
    Date,
    ComboBox,
    TextArea
}

export interface IPersonalDataRecord {
    title: string;
    nodeName: string;
    vComName?: string;
    maxLength?: number;
    type: PersonalDataRecordType|string;
    required: boolean;
    options?: IPersonalDataOption[];
}

export interface IPersonalDataForm {
    message: string;
    verifyDuplicated: boolean;
    duplicatedKeys: string[];
    records: IPersonalDataRecord[];
}

export interface IPriceListInfo {
    items: IPriceListItem[];
    forms: Record<string, IPersonalDataForm>;
}

export interface IBlockAvailability {
    id: number;
    name: string;
    numbered: number;
    totalSeats: number;
    availableSeats: number;
}

export interface ISectorAvailability {
    id: number;
    name: string;
    color: string;
    blocks: IBlockAvailability[];
}

export interface IEvent {
    id: Guid;
    startTime: string | Date;
    imageHUrl: string;
    imageVUrl: string;
    name: string;
    place: string;
    webId: number;
    slug: string;
    movie?: IMovie;
    venue: IVenue;
    description: ILocalString;
    abstract: ILocalString;
    title: ILocalString;
    note: ILocalString;
    tickets: IEventTicket[];
    flags: IEventFlag[];
    tags?: string[];
    visibleFrom: string | Date;
    visibleTo: string | Date;
    isActive: boolean;
    priceList: IPriceListInfo;
    uniqueTag: string;
    maxReserve: number;
    minReserve: number;
    sectors: ISectorAvailability[];
    error: IApiError;
    //Addictional client-data
    category?: Nts100EventCategory;
    mode?: AdvertizeMode;
    link?: ILocalString;
    linkName?: ILocalString;
}

export interface IEventTicket {
    id: Guid;
    name: string;
    price: number;
    isActive: boolean;
    freeSeats: number;
    freeSeatsVCom: number;
    totalSeats: number;
    isReduced: boolean;
    color: string;
    visibleFrom: string | Date;
    visibleTo: string | Date;
    blockIds: number[];
    sectorIds: number[];
    sectorName: string;
    preSale: number;
    maxReserve: number;
    minReserve: number;
    onlyMultiple: number;
    priceListId: number;
    reducedId: number;
    description: ILocalString;
    deliveryMethods: number;
    order: number;
    requireSubscription: boolean;
}

export interface IEventFlag {
    name: string;
    value: number;
}


export interface IListFilter {
    start: number;
    count: number;
}

export interface ITicketFilter extends IListFilter {

    uniqueTag?: string;
    advertizeId: Guid;
    eventIds: Guid[];
}

export interface ISeatInfo {
    blockId: number;
    seatName?: string;
    rowName?: string;
    blockName: string;
    sectorName: string;
    blockNote?: string;
    sectorNote?: string;
}

export type ListDisplayMode = "grid" | "table";

export interface IAdvertize {
    id: Guid;
    description: ILocalString;
    abstract: ILocalString;
    title: ILocalString;
    note: ILocalString;
    viewMode: ListDisplayMode;
    place: string;
    name: string;
    webId: number;
    appId: string;
    slug: string;
    imageHUrl: string;
    imageVUrl: string;
    startTime: JsonDate;
    endTime: JsonDate;
    visibleFrom: JsonDate;
    visibleTo: JsonDate;
    isActive: boolean;
    link?: ILocalString;
    linkName?: ILocalString;
}

export interface ITicket {
    id: Guid;
    orderId: Guid;
    eventId: Guid;
    eventTicketId: Guid;
    barcode: string;
    orderPaymentId: string;
    firstName: string;
    lastName: string;
    seat: ISeatInfo;
    isConfirmed: boolean;
    uniqueTag?: string;
    checkInDate?: JsonDate;
}

export interface IAdvertizeFilter extends IListFilter {
    advertizeIds: Guid[];
    advertizeWebIds: number[];
    parentAdvertizeId: Guid;
    startTime?: Date;
    onlyActive?: boolean;
}

export interface IEventFilter extends IListFilter {
    advertizeId?: Guid;
    merchantId?: Guid;
    appId?: string;
    eventIds: Guid[];
    eventWebIds: Guid[];
    onlyActive: boolean;
    startTime: string | Date;
    endTime: string | Date;
    language: string;
    fields: EventViewField[];
    query: string;
    includeNotFiscal?: boolean;
}


export interface IVenue {
    space: string;
    spaceCode: string;
    spaceCmpCode: string;
    location: string;
    place: string;
    fullAddress: string;
    latitude: number;
    longitude: number;
    alias?: string;
}

export interface IListResult<T> {
    items: T[];
    total: number;
    lastUpdate: string | Date;
}

export enum Gender {
    Unspecified,
    Male,
    Female,
    Other
}


export interface IUserProfile {
    id: Guid;
    username: string;
    fullName: string;
    terminalId: number;
    linkedCardCode: string;
    isBlackList: boolean;
    details: IUserDetails;
    isAuthorized: boolean;
}

export interface IAddress {
    id: Guid;
    type: string;
    streetName: string;
    city: string;
    district: string;
    countryCode: string;
    postalCode: string;
    eMail: string;
    mobilePhone: string;
    isDefault: boolean; 
}

export interface IUserDetails {
    firstName: string;
    lastName: string;
    taxCode: string;
    mobilePhone: string;    
    eMail: string;
    gender: Gender;
    birthDate: JsonDate;
    birthDistrict: string;
    birthPlace: string;
    addresses: IAddress[];
    hasAcceptedData: boolean;
}

export interface IMapFileQuery {
    spaceSiaeCode?: string;
    mapCode?: string;
    type: MapFileType;
}


export interface ISeatStateEntry {
    blockId: number;
    seat: string;
    row: string;
    state: string;
}

export interface ISectorInfo {
    id: number;
    name: string;
    color: string;
    blockIds: number[];
}

export interface IBlockInfo {
    id: number;
    numbered: boolean;
    name: string;
}


export interface ISeatStateInfo {
    seats: ISeatStateEntry[];
    sectors: ISectorInfo[];
    blocks: IBlockInfo[];
}

export interface ISeatUpdate {

    validate?: boolean;

    personalData?: Record<string, string>;
}

export interface ILockSeatInfo {
    blockId: number;
    sectorId: number;
    row?: string;
    seat?: string;
    count?: number;
    priceListId?: number;
    reducedId?: number;
}

export interface ILockBestSeatInfo {
    sectorId: number;
    entries: {
        priceListId: number,
        reducedId: number,
        count: number
    }[];
}

export interface ILockSeatResult {
    connectionId: number;
}

export interface IReservedSeat {

    reservationId?: number;
    blockId: number;
    sectorId: number;
    priceListId: number;
    reducedId: number;
    row?: string;
    seat?: string;
    personalData?: Record<string, string>;
}

export interface ILockBestSeatResult {
    token: string;
    reservation: IReservationView;
}

export interface IReservationView {
    connectionId: number;
    isFiscalized: boolean;
    isConfirmed: boolean;
    creationTime: Date | string;
    expireTime: Date | string;
    seats: IReservedSeat[];
}

export interface IDeliveryMethod {
    id: Guid;
    value: number;
    code: string;
    name: string;
    description: ILocalString;
    price: number;
    iconUrl: string;
}

export interface IApiError {
    code: number;
    message: string;
    type: string;
}

export interface IBoxolUser {
    userId: Guid;   
    userName: string;   
    name: string;   
    lastName: string;
}

export enum ResMonitorStatus {
    Hidden,
    Cancelling,
    Cancelled,
    CancellationError
}


export interface IResMonitorData {
    token: string;
    appId: string;
    eventId: Guid;
    user: IBoxolUser;
    ipAddress: string;
    userAgent: string;
    expireTime: JsonDate;
    status: ResMonitorStatus;
    error: string;
}

export interface IMovie {
    id: string;
    pin: string;
    director: string;
    duration: string;
    title: string;
    originalTitle: string;
    venue: string;
    description: ILocalString;
    image?: string;
}


export interface IVenueFilter {

}

export interface IUserView {
    id: Guid;
    fullName: string;
    username: string;
}


export enum AggregateFunc {
    None,
    Count,
    Max,
    Min,
    Avg,
    Sum
}


export interface IValidationResult {
    errorMessage: string;
    isValid: boolean;
}

export interface ICheckCartResult {
    token: string;
    connectionId: number;
    isOrderConfirmed: boolean;
    eventId: Guid;
    eventName: string;
}

export enum TimeSerieDataType {
    Text,
    Number,
    Date,
    Any,
    Seconds,
    Currency
}

export interface ITimeSerieFieldValue {
    text: string;
    subText: string;
    value: unknown;
}

export interface ITimeSerieInfo {
    name: string;
    dimensions: {
        name: string;
        isDynamic: boolean;
        canFilter: boolean;
        type: TimeSerieDataType;
        values: ITimeSerieFieldValue[]
    }[];
    metrics: {
        name: string;
        type: TimeSerieDataType;
    }[]
}

export enum FilterOperator {
    Contains,
    Equals,
    Any

}

export interface ITimeSerieFieldFilter {
    isNegate?: boolean;
    operator: FilterOperator;
    fieldName: string;
    values: string[];
}

export interface ITimeSerieFilter {
    startTime?: Date;
    endTime?: Date;
    groupBySeconds?: number;
    groupBy?: string[];
    aggregateFunc?: AggregateFunc;
    orderBy?: string;
    orderAscending?: boolean;
    minValue?: number;
    maxValue?: number;
    metric?: string;
    dimensions?: ITimeSerieFieldFilter[];
}

export interface ITimeSerieValue {
    startTime: JsonDate;
    endTime: JsonDate;
    value: {
        count: number;
        max: number;
        min: number;
        avg: number;
        sum: number;
    },
    dimensions: Record<string, unknown>;
}

export type CheckoutTicketState = "NotEmitted" | "NotPaid" | "Paid" | "PaymentInProgress" | "Emitted" | "Printed" | "EmissionCancelled";


export interface ICheckoutTicketInfo {
    withdrawId: number;
    firstName: string;
    lastName: string;
    totalAmount: number;
    connectionId: number;
    seatDescription: string;
    state: CheckoutTicketState;
}

export interface ICheckoutInfo {
    tickets: ICheckoutTicketInfo[];
    errorMessage: string;
}

export interface IPaymentInfo {
    url: string;
    poolMode: boolean;
    paymentId: string;  
}

export enum PaymentStatus {
    None,
    Initialized,
    NotAuthorized,
    Authorized,
    AuthorizationRevoked,
    Capturing,
    Captured,
    RejectedCapture,
    Refunding,
    Refunded
}

export enum UserFields {
    None = 0,
    Name = 1 << 0,
    LastName = 1 << 1,
    Email = 1 << 2,
    Address = 1 << 3,
    FiscalCode = 1 << 4,
    BirthDate = 1 << 5,
    BirthPlace = 1 << 6,
    Gender = 1 << 7,
    DeliveryAddress = 1 << 8,
    Password = 1 << 9,
    StrongValidation = 1 << 10,
    Cellular = 1 << 11,
    OtpValidation = 1 << 12
}

export interface IValidateRegistrationLevelResult {

    userId: Guid;
    missingFields: UserFields;  
}

export interface IOptInView {
    id: number;
    abstract: string;
    text: string;
    required: boolean;
    defaultValue: boolean;
    useCheckBox: boolean;
}

export interface IRegisterUserDetails {
    userRegistrationLevel: number;
    fields: UserFields;
    optins: IOptInView[];
}
export interface IRegisterUserAddress {
    postalCode?: string;
    city?: string;
    district?: string;
    countryCode?: string;
    street?: string;
    phoneNumber?: string;
    taxCode?: string;
}

export interface IRegisterUserData {
    birthDate?: Date;
    name?: string;
    lastName?: string;
    fiscalCode?: string;
    birthPlace?: string;
    birthDistrict?: string;
    email?: string;
    gender: Gender; 
    cellular?: string;
    password?: string;
    address?: IRegisterUserAddress;
    optIns?: Record<number, boolean>;
}
export interface IRegisterUserResult {
    validations: IValidationResult[];
    userId: Guid;
}