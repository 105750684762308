import { Typography } from "@mui/material";
import { MouseEventHandler, ReactNode } from "react";
import { formatText } from "../Services/Localization";
import { toCssClass } from "../Utils";
import "./Field.scss";

interface IFieldProps {
    label: string;
    children: ReactNode;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export default function Field(props: IFieldProps) {

    if (!props.children || props.children === "")
        return null;

    return (
        <div onClick={props.onClick} className={"field field-" + toCssClass(props.label)} >
            <Typography variant="overline">{formatText(props.label)}</Typography>
            <Typography variant="body1">{props.children}</Typography>
        </div>
    );
}