import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { Typography } from '@mui/material';
import { formatText } from '../Services/Localization';
import "./NotFound.scss";

export function NotFound() {

    return <div className="not-found">
        <Typography variant="h4">{formatText("msg-content-not-found")}</Typography>
        <DoNotDisturbIcon/>
    </div>
}