import { ReactNode, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from "@mui/material/Button/Button";
import "./ExpandText.scss";

export interface IExpandTextProps {
    isExpanded: boolean;
    maxHeight: number;
    children: ReactNode;
    className?: string;
}


export function ExpandText(props: IExpandTextProps) {

    const [isExpanded, setIsExpanded] = useState(props.isExpanded);

    const onExpand = () => {

        setIsExpanded(!isExpanded);
    }

    let classNames = ["expand-text"];

    classNames.push(isExpanded ? "expanded" : "collapsed");
    if (props.className)
        classNames.push(props.className);

    return <div className={classNames.join(" ")} style={{ maxHeight: isExpanded ? "unset" : props.maxHeight + "px"}}>
        <div className="content">
            {props.children} 
        </div>
        <Button onClick={onExpand} variant="text"><ExpandMoreIcon/></Button>
    </div>
}