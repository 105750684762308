import { Autocomplete, Button, Checkbox, Container, FormControlLabel, TextField, Typography } from "@mui/material";
import { dataService } from "../Services/DataService";
import { TicketListContent } from "./TicketList";
import { createContent } from "../Components/Content";
import { useState } from "react";
import { IUserView, apiClient } from "boxol-front";
import { formatDateValue, getCurrentCulture } from "../Services/Localization";
import { useActions } from "../Hooks/ActionHook";
import { IDebugOptions } from "../Entites";
import "./DebugOptions.scss";

interface IDebugOptionsProps {
    users: IUserView[];
}


function DebugOptions(props: IDebugOptionsProps) {

    const [options, setOptions] = useState<IDebugOptions>(dataService.getDebugOptions());

    const actions = useActions();

    const saveOptions = (newState: Partial<IDebugOptions>) => {
        const value = {
            ...options,
            ...newState
        };
        setOptions(value);
        dataService.setDebugOptionsAsync(value);
    }

    const mockDateValue = formatDateValue(options.mockDate);

    const mockUser = options.mockUserId ? props.users.find(a => a.id == options.mockUserId) : null;

    return <Container>
        <Typography variant="h5">Debug Options</Typography>
        <section>
            <FormControlLabel
                label="Use mock date"
                control={
                    <Checkbox
                        checked={options.useMockDate}
                        onChange={ev => saveOptions({ useMockDate: ev.target.checked })}
                    />
                }
            />
            <TextField
                id="date"
                label="Current date"
                type="date"
                disabled={!options.useMockDate}
                value={mockDateValue}
                onChange={ev => saveOptions({ mockDate: ev.target.value })}
                inputProps={{ lang: getCurrentCulture() }}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <FormControlLabel
                label="Use mock user"
                control={
                    <Checkbox
                        checked={options.useMockUser}
                        onChange={ev => saveOptions({ useMockUser: ev.target.checked })}
                    />
                }
            />
            <Autocomplete
                disablePortal
                disabled={!options.useMockUser}
                renderOption={(props, option: IUserView) => <li {...props} key={option.id}>
                    {option.fullName}
                </li>}
                getOptionLabel={(a: IUserView) => a.fullName}
                options={props.users}
                value={mockUser}
                onChange={(_, newValue: IUserView) => saveOptions({ mockUserId: newValue?.id })}
                renderInput={(params) => <TextField {...params} label="Mock user" />}
            />
            <FormControlLabel
                label="Generate Random Tickets"
                control={
                    <Checkbox
                        checked={options.generateRandomTickets}
                        onChange={ev => saveOptions({ generateRandomTickets: ev.target.checked })}
                    />
                }
            />
            <FormControlLabel
                label="Show Map"
                control={
                    <Checkbox
                        checked={options.showMap}
                        onChange={ev => saveOptions({ showMap: ev.target.checked })}
                    />
                }
            />
        </section>
        <Button onClick={() => actions.tickets() }>Aggiorna</Button>
    </Container>
}

export const DebugOptionsContent = createContent<IDebugOptionsProps>({

    body: args => <DebugOptions {...args} />,
    route: "/debug",
    name: "debug-options",
    title: "debug-options",
    parent: TicketListContent,
    authorize: false,
    area: "over",

    async loadAsync(args) {

        if (!args.users) {
            try {
                args.users = await apiClient.getUsersAsync();
            }
            catch {
                args.users = [];
            }
        }


        return true;
    }
});