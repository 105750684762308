import { Params, generatePath } from "react-router-dom";

export async function invokeAsync<TResult>(action: () => Promise<TResult>) {
    try {
        return await action();
    }
    catch (ex) {
        console.log(ex);
        throw ex;
    }
}

export function distict<T, TValue, TRes>(array: T[], selector: (item: T) => TValue, resSelector: (item: T) => TRes): TRes[] {
    const map = new Map<TValue, TRes>();
    for (const item of array) {
        const value = selector(item);
        if (!map.has(value))
            map.set(value, resSelector(item));
    }
    return Array.from(map.values());
}

export function delayAsync(time = 0) {
    return new Promise(res => setTimeout(res, time));
}

export function generateRoute(route: string, params?: Params) {
    const result = route;
    if (params)
        return generatePath(result, params);
    return result;
}


export function toCssClass(name: string) {
    return name.toLowerCase().replaceAll(" ", "-");
}

export function anyOf<TValue, TCond>(item: TValue, conds: TCond[], selector: (value: TValue, cond: TCond) => boolean): boolean {
    for (const cond of conds) {
        if (selector(item, cond))
            return true;
    }
    return false;
}

export function groupBy<T, TKey>(items: T[]|undefined, selector: (item: T) => TKey, sortByKey = true) {
    const map = new Map<TKey, T[]>();

    if (items) {
        for (const item of items) {
            const key = selector(item);
            if (!map.has(key))
                map.set(key, [item]);
            else
                map.get(key)?.push(item);
        }
    }

    const result = remap(map, (key, values) => ({ key, values }));

    if (sortByKey) {
        result.sort((a, b) => {
            if (typeof a.key == "string")
                return a.key.localeCompare(b.key as string);
            if (typeof a.key == "number")
                return a.key - (b.key as number);
        });
    }

    return result;
}

export function remap<TKey, TValue, TResult>(map: Map<TKey, TValue>, selector: (key: TKey, value: TValue) => Promise<TResult>, keySort?: (key: TKey) => string | number): Promise<TResult[]>;

export function remap<TKey, TValue, TResult>(map: Map<TKey, TValue>, selector: (key: TKey, value: TValue) => TResult, keySort?: (key: TKey) => string | number): TResult[];

export function remap<TKey, TValue, TResult>(map: Map<TKey, TValue>, selector: (key: TKey, value: TValue) => TResult | Promise<TResult>, keySort?: (key: TKey) => string|number) {

    const result: (TResult | Promise<TResult>)[] = [];

    const keys = Array.from(map.keys());
    if (keySort)
        keys.sort((a, b) => {
            const newA = keySort(a);
            const newB = keySort(b);
            if (typeof newA === "string")
                return newA.localeCompare(newB as string);
            return newA - (newB as number);
        })

    for (const key of keys) {
        const item = selector(key, map.get(key)!);
        result.push(item);
    }

    if (result[0] instanceof Promise)
        return Promise.all(result) as Promise<TResult[]>;

    return result as TResult[];

} 

export function sameFunction(a: Function | undefined, b: Function | undefined) {
    return a === b || a?.name === b?.name;
}

export function mapObject<TObj extends { [key: string]: any }, K extends keyof TObj, TResult>(obj: TObj, selector: (key: K, value: TObj[K]) => TResult): TResult[] {
    if (!obj)
        return null;
    const result = [] as TResult[];
    for (const key in obj)
        result.push(selector(key as any, obj[key] as any));
    return result;
}

export function preloadImageAsync(src: string) {

    return new Promise((res, rej) => {
        var image = new Image();
        image.addEventListener("load", () => res(image));
        image.addEventListener("error", () => rej());
        image.src = src;
    });
}
