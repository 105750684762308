import { stringTable } from "../Services/StringTable";

stringTable.add({
    "acc-portal": "Accreditation Portal",
    "active": "Current",
    "back": "back",
    "buy-your-ticket": "buy your ticket",
    "by-date": "by date",
    "by-movie": "by movie",
    "by-place": "by place",
    "by-events": "by events",
    "cancel-reservation": "cancel your reservation",
    "cancel-your-prenotation": "cancel your reservation",
    "code": "Tickets",
    "confirmed": "Confirmed",
    "connection-error": "You're offline or your internet connection in unstable",
    "date-time": "Date time",
    "date-time-value": "{0} at {1}",
    "directions": "Directions",
    "director": "Directed by",
    "download": "Download",
    "duration": "Running time",
    "enter-all": "collective ticket. All participant must be present",
    "single-ticket": "single ticket",
    "events": "Events",
    "free": "free",
    "free-seats-perc": "{0} available out of {1}",
    "go-to": "Access to",
    "language": "Select your language",
    "last-update": "Last update {0}",
    "location": "Location",
    "map": "Map",
    "msg-confirm-cancel-prenotation": "Are you sure to cancel your reservation?",
    "msg-server-error": "The server returned the fault excepion. Please try again later or contact the assistance.",
    "msg-ticket-update": "Unable to find your ticket?",
    "my-boxol": "My Boxol",
    "my-orders": "my-orders",
    "my-tickets": "Tickets",
    "my-tickets-long": "My tickets",
    "next": "next",
    "no": "no",
    "no-tickets": "No ticket available",
    "note": "Note",
    "order-number": "Order n° {0}",
    "order-value": "Order n° ",
    "past": "Past",
    "place": "place",
    "price-from": "from {0}",
    "profile": "Profile",
    "retry": "Try again",
    "row": "row",
    "search": "Search...",
    "seat": "seat",
    "seats": "seats",
    "sector": "sector",
    "show-in-maps": "show in maps",
    "tickets": "Tickets",
    "tickets-count": "({0} tickets)",
    "title": "Title",
    "to-confirm": "To be confirmed",
    "to-confirm-number": "to be confirmed ({0}/{1})",
    "update-now": "Update",
    "venue": "Venue",
    "yes": "yes",
    "category": "Cateogry",
    "msg-content-not-found": "Page not found or you don't have the necessary authorization",
    "msg-install-app": "Install this App in your {0} device",
    "dont-show-again": "Don't show again",
    "step": "Step {0}",
    "waiting-confirm": "The Order is still waiting for confirmation",
    "reserve-ticket": "Reserve your ticket",
    "msg-use-two-fingers": "Use two fingers to naviagate into the map",
    "ticket-num": "Ticket {0} of {1}"
}, "EN");