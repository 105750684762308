import { ReactElement, cloneElement, useEffect } from "react";
import { IContainerProps, useContentHost } from "../Hooks/ContentHostContext";
import { IContentProps } from "./Content";

export interface ISetContentProps {

    value: ReactElement<IContentProps>;

    container?: ReactElement<IContainerProps>;

    area?: string;
}

export function SetContent(props: ISetContentProps) {

    const host = useContentHost();

    useEffect(() => {

        const content = props.container ? cloneElement(props.value, { container: props.container }) : props.value;

        host.setContent(content, props.area ?? content.props.area ?? "main");

    }, [props.value]);

    return null;
}