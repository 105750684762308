import { createContext, useContext } from "react";

export interface IRefreshContext {

    () : Promise<void>;
}

export const RefreshContext = createContext<IRefreshContext>(undefined);

export function useRefresh() {
    return useContext(RefreshContext);
}