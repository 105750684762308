import { useEffect, useRef } from "react";
import { useOperation } from "../Hooks/OperationContext";

export interface IWebViewProps {
    src: string;
    title?: string;
    timeout?: number;
    onLoaded: (document: HTMLIFrameElement) => void;
}

export function WebView(props: IWebViewProps) {

    const iframe = useRef<HTMLIFrameElement>();
    const operation = useOperation();

    const getLoadState = (frame: HTMLIFrameElement) => parseInt(frame?.dataset.loadState);
    const setLoadState = (frame: HTMLIFrameElement, value) => {
        if (frame?.dataset)
            frame.dataset.loadState = value.toString();
    }

    useEffect(() => {

        const curFrame = iframe.current;

        return () => {

            if (!curFrame.dataset)
                return;

            if (getLoadState(curFrame) == 1) {
                setLoadState(curFrame, 2);
                operation.end(curFrame["@op"]);
            }
        };
    },[]);

    useEffect(() => {

        const curFrame = iframe.current;

        if (!curFrame || !props.src)
            return;

        if (curFrame.dataset.attached)
            return;

        console.log("New iframe");

        const onLoad = (loadFrame: HTMLIFrameElement) => {

            if (getLoadState(loadFrame) == 1) {

                setLoadState(loadFrame, 2);
                operation.end(loadFrame["@op"]);
            }

            if (props.onLoaded)
                props.onLoaded(loadFrame);
        }


        if (props.timeout) {
            setTimeout(() => {
                if (getLoadState(curFrame) == 1) {
                    setLoadState(curFrame, 2);
                    operation.end(curFrame["@op"]);
                }
            }, props.timeout);
        }

        curFrame.dataset.attached = "1"
        setLoadState(curFrame, 1);
        curFrame.addEventListener("load", ev => onLoad(ev.target as HTMLIFrameElement));
        curFrame.src = props.src;
        curFrame["@op"] = operation.begin({ name: "Load Web View" });

    }, [iframe.current, props.src]);


    return <iframe ref={iframe} title={props.title} className={"web-view " + (2 == 2 ? "loaded" : "loading")}></iframe>

}