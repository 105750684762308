import { AnalyticsSettigs } from "../config/IBasicAppSettings";
import { Publytics } from "./Publytics";
import { IAnalyticsService } from "./abstraction/IAnalyticsService";


export var analytics: IAnalyticsService;

export function setAnalytics(settings: AnalyticsSettigs) {
    if (settings.provider == "publytics") {
        analytics = new Publytics(settings.options);
    }
    else {
        analytics = {
            action() { },
            pageView() { }
        } as IAnalyticsService
    }
}


