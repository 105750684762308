import { ReactElement, ReactNode, createContext, useContext } from "react";
import { IContentProps } from "../Components/Content";
 

export interface IContainerProps {
    children?: ReactNode;
}

export interface IContentHostContext {

    setContent(content?: ReactElement<IContentProps>, area?: string): void;

    getContent(area?: string): ReactElement<IContentProps> | undefined;
}


export const ContentHostContext = createContext({} as IContentHostContext);


export function useContentHost() {
    return useContext(ContentHostContext);
}