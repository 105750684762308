import { Button, Container, Divider, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { AdvertizeMode, AnalyticsAction, Guid, IEvent, IVenue } from "boxol-front";
import { dataService } from "../Services/DataService";
import { formatCurrency, formatDate, formatText, formatTime } from "../Services/Localization";
import { IContentTitle, createContent } from "../Components/Content";
import NoPoster from "../Assets/NoPoster.png"
import Section from "../Components/Section";
import CircleIcon from '@mui/icons-material/Circle';
import { Fragment, useState } from "react";
import StaticGeoMap from "../Components/StaticGeoMap";
import { appSettings } from "../Services/AppSettings";
import { stringTable } from "../Services/StringTable";
import MapViewer, { IMapViewerProps } from "../Components/MapViewer";
import { MapView } from "@attractive/map-viewer";
import { useNetwork } from "../Hooks/NetworkHook";
import { Loading } from "../Components/Loading";
import { geoMapService } from "../Services/GeoMapService";
import Field from "../Components/Field";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { useUser } from "../Hooks/UserContext";
import "./EventDetails.scss";
import Html from "../Components/Html";
import { ExpandText } from "../Components/ExpandText";

interface IEvemtDetailsProps extends IContentTitle {
    eventId: Guid;
    event?: IEvent;
    venue?: IVenue;
    showMap?: boolean;
    showAvailabilityNumber?: boolean;
}


function EventDetails(props: IEvemtDetailsProps) {

    const theme = useTheme();

    const { user } = useUser();

    const movie = props.event?.movie!;

    const validTickets = dataService.getValidTickets(props.event!);

    const network = useNetwork();

    const [isMapLoaded, setIsMapLoaded] = useState(false);


    const venueCenter = {
        latitude: props.venue?.latitude,
        longitude: props.venue?.longitude,
    }

    const openBuy = () => {
      
        const root = appSettings.boxolWebRoot;
        const url = `${root}${appSettings.instance.appId}/${stringTable.activeLanguage.toLowerCase()}/event/${props.event.slug?.length == 0 ? "slug" : props.event.slug}/${props.event.webId}`;
        window.open(url);


    }

    const mapOptions: IMapViewerProps["options"] = {
        styles: {
            "block": {
                text: {
                    fontSize: 19
                },
                strokeWidth: 1,
                strokeColor: "#999"
            },
            "seat": {
                text: {
                    fontSize: 12,
                    color: "#fff"
                },
                fillColor: "#ccc"
            },
            "L": {
                fillColor: "#4caf50",
            },
            "NA": {
                fillColor: "transparent",
            },
            "W": {
                fillColor: "#ffc107",
            },
            "P": {
                fillColor: "#F44336",
            },
            "A": {
                fillColor: "transparent",
            },
        },
    };

    const onMapReady = async (mapView: MapView) => {

        const stateMap = await network(() => dataService.getSeatStateCompactAsync(props.eventId), { isLocal: true, operation: "loadSeatState" });

        const newStateMap = {};

        for (const entry in stateMap) {

            const stateKey = entry == "P" ? "P" : entry;
            if (!newStateMap[stateKey])
                newStateMap[stateKey] = [];

            newStateMap[stateKey].push(...stateMap[entry]);
        }

        mapView.map.setSeatsState(stateMap);
        mapView.mobileMode = document.body.clientWidth < 600 && navigator.maxTouchPoints > 0;

        setIsMapLoaded(true);
    }

    const category = dataService.getEventTagInfo(props.event);

    let showMap = props.showMap;
    if (user != "no-user" && (user?.username == "aguerrieri82@gmail.com" || user.username == "elisa.marinotti@gmail.com"))
        showMap = true;

    let buyText = "";
    if (validTickets && validTickets.every(a => a.price == 0))
        buyText = "reserve-ticket";
    else
        buyText = "buy-your-ticket";

    const description = formatText(props.event.description);

    const hasTickets = validTickets && validTickets.length > 0;

    return <Container sx={{ overflowY: "auto" }}>
        <header>
            <Typography variant="h5" className="title">{props.title}</Typography>
        </header>
        {movie && <Section className="movie-info">
            <Fragment>
                <img className="poster" alt="poster" src={movie.image ?? NoPoster} />
                <Field label={formatText("director")} >{movie.director}</Field>
                <Field label={formatText("duration")}>{movie.duration}</Field>
                <Field label={formatText("category")}>{formatText(category?.name)}</Field>
                <div style={{ clear: "both" }} ></div>
            </Fragment>
        </Section>}


        <Section className="buy">
            <Typography component="div">
                <div className="date">
                    <InsertInvitationIcon />
                    <span>{formatDate(props.event.startTime)}</span>
                </div>
                <div className="time">
                    <AccessTimeIcon />
                    <span>{formatTime(props.event.startTime)}</span>
                </div>
            </Typography>
            <div className="row">
                {hasTickets &&
                    <AnalyticsAction action="buy_event" label={props.event?.webId}>
                        <Button onClick={openBuy} variant="contained">{formatText(buyText)}</Button>
                    </AnalyticsAction>
                }
                {!hasTickets && props.event.mode != "ViewOnly" &&
                    <Button className="red" variant="contained">{formatText("sold-out")}</Button>
                }
                {!hasTickets && props.event.mode == "ViewOnly" &&
                    <Button className="blue" variant="contained">{formatText("free-event")}</Button>
                }
            </div>
        </Section>
        <Section className="event-info">
            {description && <ExpandText maxHeight={200} isExpanded={false}> 
                <Typography><Html>{description}</Html></Typography>
            </ExpandText>}
        </Section>
        <Section className="tickets">
            {hasTickets &&
                <List>
                    {validTickets.map(a => {

                        const color = a.freeSeats <= 0 ? "red" : (a.freeSeats / a.totalSeats < appSettings.ticketWarnValue ? "yellow" : "green");
                        return <Fragment key={a.id}>
                            <ListItem className={a.isReduced ? "reduced" : ""} >
                                <div className="row">
                                    <ListItemIcon>
                                        <CircleIcon className={color} />
                                    </ListItemIcon>
                                    <ListItemText
                                        className="body"
                                        primary={a.name} />
                                    <ListItemText className="price"
                                        primary={a.price == 0 ? formatText("free") : formatCurrency(a.price + a.preSale)} />
                                </div>
                                {a.totalSeats && <div className="row seats">

                                    <div className="progress-bar">
                                        <div style={{
                                            width: (a.freeSeats / a.totalSeats * 100) + "%",
                                            backgroundColor: theme.palette.primary.main
                                        }}></div>
                                    </div>
                                    {props.showAvailabilityNumber && <Typography variant="body2">{formatText("free-seats-perc", a.freeSeats, a.totalSeats)}</Typography>}
                                </div>}
                            </ListItem>
                            <Divider key={a.id + "-div"} />
                        </Fragment>
                    })}
                </List>
            }

            {!hasTickets && props.event.mode != "ViewOnly" &&
                <Typography>{formatText("no-tickets")}</Typography>
            }

            {props.venue?.spaceCmpCode && showMap &&
                <div className="map-container">
                    <MapViewer src={dataService.getMapFileUrl(props.venue.spaceCmpCode)} options={mapOptions} onReady={onMapReady} />
                    {isMapLoaded || <Loading />}
                </div>
            }
        </Section>
        {props.venue &&
            <Section header={formatText("location")}>
                <Typography variant="h6">{props.venue.location}</Typography>
                <Typography variant="subtitle1">{props.venue.space}</Typography>
                <StaticGeoMap options={{
                    center: venueCenter,
                    zoomLevel: 15,
                    size: {
                        width: window.innerWidth,
                        height: Math.round(window.innerWidth * 8 / 16)
                    },
                    pins: [{
                        center: venueCenter,
                        name: "",
                        icon: 113
                    }]
                }} />
                <address>
                    <Typography>{props.venue.fullAddress}</Typography>
                </address>
                <div className="actions">
                    <Button variant="text" onClick={() => geoMapService.openMaps(venueCenter)}>{formatText("show-in-maps")}</Button>
                    <Button variant="text" onClick={() => geoMapService.openDirections(venueCenter)} >{formatText("directions")}</Button>
                </div>
            </Section>
        }
    </Container>;
}

export const EventDetailsContent = createContent<IEvemtDetailsProps>({

    body: args => <EventDetails {...args} />,
    route: "/events/:eventId",
    name: "event-details",
    parent: "event-list",
    authorize: false,
    area: "over",

    async loadAsync(args) {
        const options = dataService.getDebugOptions();
        args.showMap = appSettings.showAvailabilityMap || (appSettings.isDebug && options.showMap);
        args.showAvailabilityNumber = appSettings.showAvailabilityNumber;
        args.event = await dataService.getEventByIdAsync(args.eventId, true);
        if (!args.event)
            return false;
        args.title = dataService.getEventTitle(args.event);

        args.venue = await dataService.getVenueByPlaceAsync(dataService.getEventVenue(args.event));

        return true;
    }
});