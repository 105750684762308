
import { Box, useTheme } from "@mui/material";
import { ReactElement, ReactNode, cloneElement } from "react";

import "./SwitchSelector.scss";

interface ISwitchSelectorProps<TValue> {
    value?: TValue;
    onChanged?: (value: TValue) => void;
    children: ReactElement<ISwitchSelectorItemProps<TValue>>[];
    selectedColor?: string;
}


export interface ISwitchSelectorItemProps<TValue> {
    children: ReactNode;
    value: TValue;
    container?: ISwitchSelectorProps<TValue>;
}


export function SwitchSelectorItem<TValue = any>(props: ISwitchSelectorItemProps<TValue>) {

    const theme = useTheme();

    const isSelected = props.container.value == props.value;
    const bkColor = isSelected ? (props.container.selectedColor ?? theme.palette.primary.main) : "";

    return <Box className={"switch-item" + isSelected ? " selected" : ""} sx={{backgroundColor: bkColor}} onClick={() => props.container.onChanged ? props.container.onChanged(props.value) : undefined}>
        {props.children}
    </Box>
}

export function SwitchSelector<TValue = any>(props: ISwitchSelectorProps<TValue>) {

    if (!props.children)
        return null;

    return (
        <Box sx={{ typography: 'body1' }} className={"switch-selector"} >
            {props.children.map(a => cloneElement(a, { container: props }))}
        </Box>
    );
}