import { ReactElement } from "react";
import { ContentRef, IContentProps } from "../Components/Content";

const contents: ReactElement<IContentProps>[] = [];

export function registerContent(content: ReactElement<IContentProps>) {
    contents.push(content);
} 

export function getContents() {
    return contents;
}

export function resolveContent(contentRef: ContentRef) {

    if (typeof contentRef == "string")
        return contents.find(a => a.props.name == contentRef);

    return contentRef;
}
