import { Fragment, useEffect, useState } from "react";
import SwipeableViews from 'react-swipeable-views';
import { Box, Button, Container, MobileStepper, Typography, useTheme } from "@mui/material";
import { IEvent, ITicket } from "boxol-front";
import { dataService } from "../Services/DataService";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import Field from "../Components/Field";
import { formatDate, formatText, formatTime } from "../Services/Localization";
import { TicketListContent } from "./TicketList";
import { createContent } from "../Components/Content";
import { useNetwork } from "../Hooks/NetworkHook";
import { IBaseTicketsProps } from "./TicketsDetails";
import UpdateIcon from '@mui/icons-material/Update';
import { distict } from "../Utils";
import "./TicketsCode.scss";

interface ITicketDetailsViewProps {
    ticket: ITicket;
    event: IEvent;
    total?: number;
    index?: number;
}

interface IOrderDetailsViewProps {
    orderCode: string;
    total: number;
    event: IEvent;
}


function OrderDetailsView(props: IOrderDetailsViewProps) {

    const [codeUrl, setCodeUrl] = useState<string>();

    const network = useNetwork();

    useEffect(() => {
        network(async () => {
            setCodeUrl(await dataService.getBarcodeAsync("GB/-" + props.orderCode));
        });
    }, []);


    return <div className="ticket-details">

        <Box component="header">
            <Typography variant="h5">{dataService.getEventTitle(props.event)}</Typography>


            <div className="row inline">
                <Field label="date">{formatDate(props.event!.startTime!)}</Field>
                <Field label="time">{formatTime(props.event!.startTime!)}</Field>
                <div className="filler" />
            </div>
            <div className="row">
                <Field label="place">{formatText(props.event!.place!)}</Field>
                <div className="filler" />
            </div>
            <Typography variant="h4" className="center" >{formatText("enter-all")}</Typography>
            <Typography variant="h6" className="center" >{formatText("tickets-count", props.total.toString())}</Typography>

            <Typography className="order">{formatText("order-value")}<Typography component="span" >{props.orderCode}</Typography></Typography>
        </Box>


        <Box className="separator">
            <div className="left"></div>
            <Box className="center"></Box>
            <div className="right"></div>
        </Box>

        <Box className="code-container">
            {codeUrl ?
                <img alt={props.orderCode} src={codeUrl} />
                :
                <UpdateIcon />
            }
        </Box>


    </div>
}

function TicketDetailsView(props: ITicketDetailsViewProps) {

    const [codeUrl, setCodeUrl] = useState<string>();

    const network = useNetwork();

    const ticket = props.ticket;

    useEffect(() => {
        network(async () => {
            if (props.ticket.barcode)
                setCodeUrl(await dataService.getBarcodeAsync(ticket.barcode));
        });
    }, []);

    return <Box className="ticket-details" >

        <Box bgcolor="secondary.main" component="header">
            <Typography variant="h5">{dataService.getEventTitle(props.event)}</Typography>
            <div className="row inline">
                <Field label="date">{formatDate(props.event!.startTime!)}</Field>
                <Field label="time">{formatTime(props.event!.startTime!)}</Field>
                <div className="filler" />
            </div>
            <div className="row inline">
                <Field label="place">{formatText(props.event!.place!)}</Field>
                <div className="filler"/>
            </div>

            <div className="row">
                <Field label="sector">{ticket.seat.sectorName}</Field>
                <Field label="row">{ticket.seat.rowName}</Field>
                <Field label="seat">{ticket.seat.seatName}</Field>

            </div>
            {ticket.seat.blockNote && <div className="row inline">
                <Field label="note">{formatText(ticket.seat.blockNote)}</Field>
            </div>}
            <Typography variant="h4">{ticket.firstName + " " + ticket.lastName}</Typography>

            <Typography className="order">{formatText("order-value")}<Typography component="span" >{ticket.orderPaymentId}</Typography></Typography>
            {props.total && <Typography className="ticket-num" variant="body1">{formatText("ticket-num", props.index?.toString(), props.total?.toString())}</Typography>}

        </Box>


        <Box bgcolor="secondary.main" className="separator">
            <div className="left"></div>
            <Box className="center"></Box>
            <div className="right"></div>
        </Box>

        <Box bgcolor="secondary.main" className="code-container">
            {codeUrl ?
                <img alt={ticket.barcode} src={codeUrl} />
                :
                <UpdateIcon />
            }
        </Box>

    </Box>
}


interface ITicketsCodeProps extends IBaseTicketsProps {
}

function TicketsCode(props: ITicketsCodeProps) {

    const theme = useTheme();

    const [activeStep, setActiveStep] = useState(0);


    const onNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const orders = distict(props.tickets, a => a.orderPaymentId, a => a.orderPaymentId);

    const allTickets = [];
    if (props.tickets.length > 1) {

        allTickets.push(...props.tickets!.map((a, i) => <TicketDetailsView ticket={a} index={i + 1} total={props.tickets.length} event={props.event} key={a.id} />));

        if (orders.length == 1 && props.tickets.every(a=> a.isConfirmed))
            allTickets.push(<OrderDetailsView event={props.event} orderCode={orders[0]} total={props.tickets.length} key={orders[0]} />);
    } 

    const maxSteps = allTickets.length;

    return <Container>

        {props.tickets.length > 1 ?
            <Fragment>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    className="swiper"
                    enableMouseEvents
                >
                    {allTickets}
                </SwipeableViews>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={onNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            {formatText("next")}
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            {formatText("back")}
                        </Button>
                    }
                />
            </Fragment>
            :
            <TicketDetailsView event={props.event} ticket={props.tickets[0]} />
        }

    </Container>

}

export const TicketsCodeContent = createContent<ITicketsCodeProps>({

    body: args => <TicketsCode {...args} />,
    route: "/tickets/:eventId/code",
    name: "tickets-code",
    title: "code",
    parent: TicketListContent,
    authorize: true,

    async loadAsync(args) {

        if (!args.tickets) {
            const tickets = await dataService.getTicketsAsync(false);
            args.tickets = tickets.filter(a => a.eventId == args.eventId);
        }

        if (!args.event)
            args.event = (await dataService.getEventByIdAsync(args.eventId))!;

        return true;
    }
});