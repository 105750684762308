import { ReactElement, createContext, useContext } from "react";
import { LocalString } from "../Services/Localization";

export interface IAppBarState {
    backAction?: () => void;
    title?: LocalString;
    custom?: ReactElement;
    loading?: boolean;
}

export interface IAppBarContext {
    setState: (value: IAppBarState) => void;
}

export const AppBarContext = createContext<IAppBarContext>(null as any);


export default function useAppBar() {
    return useContext(AppBarContext);
}