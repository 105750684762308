import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { testPostMessage } from "boxol-front"

export function usePageView() {

    const location = useLocation()

    useEffect(() => {

        testPostMessage("location.changed", location.hash);

    }, [location.hash])
}