import { parseDate } from "./DateTime";
import { stringTable } from "./StringTable";

export type LocalString = { (): string } | { [lang: string]: string } | string;

export function formatCurrency(value: number) {
    return (Math.round(value * 100) / 100).toFixed(2) + " €";
}

export function formatDateValue(date: Date | string) {
    if (!date)
        return "";

    const realDate = parseDate(date);
    return realDate.getFullYear() + "-" + realDate.toLocaleDateString(stringTable.activeLanguage, { month: "2-digit" }) + "-" + realDate.toLocaleDateString(stringTable.activeLanguage, { day: "2-digit" });
}

export function formatText(text: LocalString, ...args: any[]): string {

    let newText;
 
    if (typeof text == "function")
        newText = text();
    else if (text && typeof text == "object") {
        newText = text[stringTable.activeLanguage];
        if (!newText)
            newText = text[Object.keys(text)[0]];
    } 
    else
        newText = text;
    newText = stringTable.get(newText);
    if (args && args.length > 0)
        return replaceArgs(newText, name => formatText(args[parseInt(name)]));
    return newText;
}


export function formatDateWeekDayNoYear(date: Date | string): string {

    if (!date)
        return "";

    const realDate = parseDate(date);

    return realDate.toLocaleString(stringTable.activeLanguage, {
        month: "long",
        day: "numeric",
        weekday: "long"
    }).replace(",", " ");
}

export function formatTime(date: Date | string) {

    if (!date)
        return "";

    const realDate = parseDate(date);

    return realDate.toLocaleString(stringTable.activeLanguage, {
        hour: "numeric",
        minute: "2-digit"
    }).replace(",", " ");
}


export function formatDate(date: Date | string) {

    if (!date)
        return "";

    const realDate = parseDate(date);

    return realDate.toLocaleString(stringTable.activeLanguage, {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
    }).replace(",", " ");
}

export function formatDateTime(date: Date | string): string {

    if (!date)
        return "";

    const realDate = parseDate(date);

    return realDate.toLocaleString(stringTable.activeLanguage, {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit"
    }).replace(",", " ");
}

export function getCurrentCulture() {
    const lang = stringTable.activeLanguage.toUpperCase();
    if (lang == "IT")
        return "it-IT";
    if (lang == "EN")
        return "en-US";

}

export function replaceArgs(text: string, args: (arg: any) => string | Record<string, any>): string {

    if (!text)
        return text;

    let map: { (arg: any) : string }

    if (typeof (args) != "function")
        map = key => args[key];
    else
        map = args as any;

    let state = 0;
    let result = "";
    let curName = "";

    for (let i = 0; i < text.length; i++) {
        const c = text[i];
        switch (state) {
            case 0:
                if (c == "{") {
                    curName = "";
                    state = 1;
                }
                else
                    result += c;
                break;
            case 1:
                if (c == "}" || c == ":" || c == "=") {
                    state = 0;

                    result += map(curName);

                    if (c == ":" || c == "=")
                        state = 2;
                    else
                        state = 0;
                }
                else if (c != "?")
                    curName += c;
                break;
            case 2:
                if (c == "}")
                    state = 0;
                break;
        }
    }

    return result;
}