import EventIcon from '@mui/icons-material/Event';
import { Fragment, ReactElement, ReactNode, useEffect, useMemo, useState } from "react";
import { Box, Container, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, Typography, useTheme } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { formatCurrency, formatDate, formatText, formatTime } from "../Services/Localization";
import useAppBar from "../Hooks/AppBarContext";
import { groupBy } from "../Utils";
import { IEvent } from "boxol-front";
import { dataService } from "../Services/DataService";
import { createContent } from "../Components/Content";
import NoPoster from "../Assets/NoPoster.png"
import CircleIcon from '@mui/icons-material/Circle';
import { useActions } from "../Hooks/ActionHook";
import Section, { ISectionProps } from "../Components/Section";
import { appSettings } from "../Services/AppSettings";
import KeywordSearch, { IKeyword } from "../Components/KeywordSearch";
import { parseDate, truncateTime } from '../Services/DateTime';
import { stringTable } from '../Services/StringTable';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Field from '../Components/Field';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import "./EventList.scss";
import { SearchKeywordType } from '../Entites';

interface ITabPanelProps {
    children?: ReactNode;
    dir?: string;
    index: number;
    value: number;

}

function TabPanel(props: ITabPanelProps) {

    return (
        <div
            role="tabpanel"
            hidden={props.value !== props.index}
            id={`filter-${props.index}`}
        >
            {props.value === props.index && (
                props.children
            )}
        </div>
    );
}

export type EventListFilter = "by-date" | "by-event" | "by-place";

interface IEventsProps {
    events?: IEvent[];
    filter?: EventListFilter;
}

interface IEventViewState {
    byDateContent?: ReactElement<ISectionProps>[];
    byMovieContent?: ReactElement<ISectionProps>[];
    byVenueContent?: ReactElement<ISectionProps>[];
}


function EventList(props: IEventsProps) {

    const theme = useTheme();

    const appBar = useAppBar();

    const actions = useActions();

    const [activeTab, setActiveTab] = useState(0);

    const [events, setEvents] = useState(props.events);

    const customAppBar = {
        custom: <Tabs
            value={activeTab}
            onChange={(_, v) => setActiveTab(v)}
            variant="fullWidth"
        >
            <Tab value={0} label={formatText("by-date")} id="filter-0" />
            <Tab value={1} label={formatText(appSettings.theme.eventFilterLabel)} id="filter-1" />
            <Tab value={2} label={formatText("by-place")} id="filter-2" />
        </Tabs>
    }

    useEffect(() => {

        appBar.setState(customAppBar);
    }, [activeTab]);

    const searchKeywords = useMemo(() => dataService.createSearchKeyword(props.events), [props.events]);

    const filterEvents = (words: IKeyword<SearchKeywordType>[]) => {
     
        setEvents(dataService.filterEvents(props.events, words));
    }

    const viewState = useMemo(() => {

        const curEvents = events
            .sort((a, b) => parseDate(a.startTime).getTime() - parseDate(b.startTime).getTime());


        //const curMovies = curEvents.filter(a => a.movie != null);

        const byDateGroup = groupBy(curEvents, a => truncateTime(a.startTime).getTime());

        const byEventGroup = groupBy(curEvents, a => dataService.getEventTitle(a));

        const byVenueGroup = groupBy(curEvents, a => dataService.getEventVenue(a));

        const eventList = (events: IEvent[], primary: (event: IEvent) => ReactNode, secondary: (event: IEvent) => ReactNode) => {

            return events.map(a => {

                const validTickets = a.tickets!.filter(a => a.freeSeats > 0 && a.isActive);

                const isOver = validTickets.length == 0 && a.mode != "ViewOnly";
                const isAlert = validTickets.length > 0 && validTickets.every(a => a.freeSeats / a.totalSeats < appSettings.ticketWarnValue);
                const color = isOver ? "red" : (isAlert ? "yellow" : (a.mode == "ViewOnly" ? "blue" : "green"));
                let minPrice = Number.POSITIVE_INFINITY;

                if (validTickets.length > 0)
                    validTickets.forEach(a => minPrice = Math.min(minPrice, a.price));
                else
                    minPrice = 0;
      
                return <Fragment key={a.id}>
                    <ListItem disablePadding >
                        <ListItemButton onClick={() => actions.eventDetails(a.id)}>
                            <ListItemIcon>
                                <div>
                                    <CircleIcon className={color} />
                                    {!isOver && <Typography variant="body2">{formatText(minPrice == 0 ? "free" : "price-from", formatCurrency(minPrice))}</Typography>}
                                </div>
                            </ListItemIcon>
                            <ListItemText 
                                primaryTypographyProps={{ component:"div" }}
                                secondaryTypographyProps={{ component:"div" }}
                                primary={primary(a)}
                                secondary={secondary(a)} />
                  
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                </Fragment>
            })
        };

        const state: IEventViewState = {};

        state.byDateContent = byDateGroup.map(a => {

            const realDate = new Date(a.key);
            const weekDay = realDate.toLocaleString(stringTable.activeLanguage, {
                weekday: "short"
            });

            const dayMonth = realDate.toLocaleString(stringTable.activeLanguage, {
                month: "long",
                day: "numeric",
                year: "numeric"
            });

            const header = <Box className="event-date">
                <span className="week-day">{weekDay}</span>
                <span className="day-month">{dayMonth}</span>
            </Box>

            return <Section
                key={a.key}
                headerVariant="h6"
                className = "date-section"
                canCollapse
                isCollapsed={byDateGroup.length > 1}
                header={header}>
                <List>
                    {eventList(a.values,
                        a => dataService.getEventTitle(a),
                        a => (<Fragment>
                            <div>
                                <AccessTimeIcon />
                                <span>{formatTime(a.startTime)}</span>
                            </div>
                            <div>
                                <LocationOnIcon />
                                <span>{dataService.getEventVenue(a)}</span>
                            </div>
                        </Fragment>)
                    )}
                </List>
            </Section>;
        })


        state.byMovieContent = byEventGroup.map(a => {

            const movie = a.values[0].movie!;

            return <Section
                canCollapse
                className="movie-section"
                isCollapsed={byEventGroup.length > 1}
                headerVariant="h6"
                header={a.key}
                key={a.key}>
                {movie &&

                    <div className="image-info">


                        <img alt="poster" src={movie.image ?? NoPoster} />
                        <div className="info">
                            <Field label={formatText("director")} >{movie.director}</Field>
                            <Field label={formatText("duration")}>{movie.duration}</Field>
                        </div>
                    </div>
                }
                <List>
                    {eventList(a.values,
                        a => (<Fragment>
                            <div className="date">
                                <InsertInvitationIcon />
                                <span>{formatDate(a.startTime)}</span>
                            </div>
                            <div className="time">
                                <AccessTimeIcon />
                                <span>{formatTime(a.startTime)}</span>
                            </div>
                            <div className="place">
                                <LocationOnIcon />
                                <span>{dataService.getEventVenue(a)}</span>
                            </div>
                        </Fragment>),
                        a => ""
                    )}
                </List>
            </Section>
        }, a => a)

        state.byVenueContent = byVenueGroup.map(a => {
            return <Section
                key={a.key}
                className="venue-section"
                canCollapse
                isCollapsed={byVenueGroup.length > 1}
                headerVariant="h6"
                header={a.key}
            >
                <List>
                    {eventList(a.values,
                        a => dataService.getEventTitle(a),
                        a => <Fragment>
                            <div >
                                <InsertInvitationIcon />
                                <span>{formatDate(a.startTime)}</span>
                            </div>
                            <div >
                                <AccessTimeIcon />
                                <span>{formatTime(a.startTime)}</span>
                            </div>
                        </Fragment>
                    )} 
                </List>
            </Section>
        }, a => a)

        return state;

    }, [events]);

    return <Container>

        <KeywordSearch
            onChanged={filterEvents}
            searchLabel={formatText("search")}
            source={searchKeywords} />

        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeTab}
            onChangeIndex={setActiveTab}
        >
            {[viewState?.byDateContent, viewState?.byMovieContent, viewState?.byVenueContent].map((a, i) => {

                const itemKey = a?.length == 1 ? a[0].props.header as string : i;
                return <TabPanel key={itemKey} value={activeTab} index={i} dir={theme.direction}>
                    {a}
                </TabPanel>
            })}

        </SwipeableViews>
    </Container>
 
}

export const EventListContent = createContent<IEventsProps>({

    body: props => <EventList {...props} />,
    title: "events",
    route: "/events",
    name: "events",
    icon: <EventIcon />,
    hasCustomAppBar: true,
    authorize: false,
    order: 1,
    async loadAsync(args, refresh) {
        if (!args.events || refresh)
            args.events = await dataService.getEventsAsync(refresh);
        return true;
    }
});