import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import { ReactNode, createElement, useState } from "react";
import { IAction } from "../Hooks/OperationContext";
import { formatText } from "../Services/Localization";
import { mapObject } from "../Utils";

interface IActionDictionary {
    [key: string]: IAction;
}

interface IMessageBoxProps<T extends IActionDictionary> {
    isOpen: boolean;
    title: string;
    children: ReactNode;
    actions?: T;
    onClose?: (value?: keyof T) => void;
}

export function MessageBox<T extends IActionDictionary>(props: IMessageBoxProps<T>) {


    return <Dialog
        open={props.isOpen}
        TransitionComponent={Slide}
        keepMounted
        className="message-box"
        onClose={() => props.onClose()}
    >
        <DialogTitle>{formatText(props.title)}</DialogTitle>
        <DialogContent>
            {props.children}
        </DialogContent>
        <DialogActions>
            {mapObject(props.actions, (k, v) =>
                <Button key={k as string} onClick={() => props.onClose(k)}>{formatText(v.label)}</Button>)
            }
        </DialogActions>
    </Dialog>
}

interface IDataContext {

    setData(key: string, value: any);

    getData<TValue>(key: string, defValue?: TValue): TValue;
}

const data: Record<string, any> = {};

export function useMessageBox<T extends IActionDictionary>(title: string, body: (ctx: IDataContext) => ReactNode, actions: T) {

    const [state, setState] = useState({
        isOpen: false,
        onClose: undefined as { (value?: keyof T) : void }
    });


    const dataContext = {

        setData(key: string, value: any) {
            data[key] = value;
            setState({ ...state });
        },

        getData<TValue>(key: string, defValue?: TValue): TValue {
            return data[key] ?? defValue;
        }
    }

    return {

        ...dataContext,

        showAsync: async () => {

            return new Promise<keyof T>(res => {

                setState({
                    isOpen: true,
                    onClose: value => {
                        res(value);
                        setState({ isOpen: false, onClose: undefined, });
                    }
                })
            });

        },

        component: createElement(MessageBox, {
            title,
            children: body(dataContext),
            actions,
            isOpen: state.isOpen,
            onClose: state.onClose
        })
    };
}


export const YesOrNoActions = {
    "yes": {
        label: "yes"
    },
    "no": {
        label: "no"
    }
}