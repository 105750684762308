import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Snackbar } from '@mui/material';
import { MouseEvent, ReactElement, ReactNode, useState } from 'react';
import { AlertType, IAction, IOperation, IOperationContext, OperationContext } from '../Hooks/OperationContext';
import RefreshIcon from '@mui/icons-material/Refresh';
import { mapObject } from '../Utils';
import { testPostMessage } from 'boxol-front';
import "./Operation.scss";
interface IErrorMessage {

    body: ReactNode;
    action?: string;
    callback?: (value: boolean) => void;
}

interface IActionMessage<T extends { [key: string]: IAction }> {
    title: string;
    body: ReactNode;
    actions?: T;
    callback?: (value?: keyof T) => void;
}

interface IAlertMessage {
    body: ReactNode;
    type: AlertType;
    duration?: number;
}

interface IOperationState {
    blockCount: number;
    progressMessage: string;
    showProgress: boolean;
    actionMessage: IActionMessage<{ [key: string]: IAction }>;
    errorMessage: IErrorMessage;
    alertMessage: IAlertMessage;
}

const state = {
    blockCount: 0,
    showProgress: true,
    alertMessage: null,
    errorMessage: null
} as IOperationState

const activeOperations :IOperation[] = []

let refresh: boolean;

let setRefresh: (value: boolean) => void;

const doRefresh = () => setRefresh(!refresh);

export default function Operation(props: { children: ReactNode }) {

    const states = useState(false);
    refresh = states[0];
    setRefresh = states[1];

    const operation: IOperationContext = {
        begin: (options) => {

            const result: IOperation = {
                name: options?.name,
                id: new Date().getTime(),
            }

            activeOperations.push(result);

            if (options?.showProgress !== undefined && state.blockCount == 0) 
                state.showProgress = options.showProgress;

            testPostMessage("operation.begin", { blockCount: state.blockCount });

            console.log("begin: ", options?.name, result.id, state.blockCount);

            state.blockCount++;

            doRefresh();

            return result;
        },
        end: (op: IOperation) => {
      
            const index = activeOperations.indexOf(op);
            if (index == -1) {
                console.log("End a non existing operation: ", op, state.blockCount);
                return;
            }
            activeOperations.splice(index, 1);

            state.blockCount--;

            console.log("end: ", op?.name, op?.id, state.blockCount);
            if (state.blockCount == 0)
                state.showProgress = true;

            testPostMessage("operation.end", { blockCount: state.blockCount });

            doRefresh();
        },
        showAlert: (message, type, duration) => {

            state.alertMessage = {
                body: message,
                type,
                duration
            }
            doRefresh();
        },
        showMessageAsync: async (message, title, actions) => {

            let callback: typeof state.actionMessage.callback;

            const closePromise = new Promise<keyof typeof actions>(res => callback = res);

            state.actionMessage = {
                body: message,
                title,
                actions,
                callback
            }

            doRefresh();

            const result = await closePromise;

            return result;
        },
        confirmAsync: async (message) => {
            const result = await operation.showMessageAsync(message, "Conferma operazione", {
                "yes": { label: "Si" },
                "no": { label: "No" },
            });
            return result == "yes";
        },
        showErrorAsync: async (message, actions?) => {

            const curActionId = Object.keys(actions)[0];
            const curAction = actions[curActionId];
            let callback: typeof state.errorMessage.callback;

            const closePromise = new Promise<boolean>(res => callback = res);

            state.errorMessage = {
                body: message,
                action: curAction?.label,
                callback
            }

            doRefresh();

            const result = await closePromise;

            if (result)
                return curActionId;
        }
    }

    const onAlertClose = () => {
        state.alertMessage = null;
        doRefresh();
    }

    const onErrorClose = (isAction: boolean) => {

        if (!state.errorMessage)
            return;

        if (state.errorMessage.callback)
            state.errorMessage.callback(isAction);

        state.errorMessage = null;
        doRefresh();
    }

    const onActionClose= (result?: string) => {

        if(!state.actionMessage)
            return;

        if (state.actionMessage.callback)
            state.actionMessage.callback(result);

        state.actionMessage = null;

        doRefresh();
    };

    const onBlockerClick = (ev: MouseEvent) => {
        if (state.blockCount > 0)
            ev.stopPropagation();
    }

    let errorMessageAction: ReactElement = null;

    if (state.errorMessage?.action) {
        errorMessageAction = (
            <Button color="secondary" size="small" onClick={()=> onErrorClose(true)} >
            {state.errorMessage?.action}
            </Button>
        )
    }

    return (
        <OperationContext.Provider value={operation}>
            {props.children}
            <Snackbar
                className="global-error"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={state.errorMessage != null}
                onClose={() => onErrorClose(false)}
                action={errorMessageAction}
                message={state.errorMessage?.body}
            />
            <Snackbar
                className="global-alert"
                autoHideDuration={state.alertMessage?.duration}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={state.alertMessage != null}
                onClose={onAlertClose}
            >
                <Alert severity={state.alertMessage?.type }>
                    {state.alertMessage?.body}
                </Alert>
            </Snackbar>
            <Dialog
                open={state.actionMessage != null}
                TransitionComponent={Slide}
                keepMounted
                className="message-box"
                onClose={() => onActionClose() }
            >
                <DialogTitle>{state.actionMessage?.title}</DialogTitle>
                <DialogContent>
                    {state.actionMessage?.body}
                </DialogContent>
                <DialogActions>
                    {mapObject(state.actionMessage?.actions, (k, v) =>
                        <Button key={k} onClick={() => onActionClose(k as string)}>{v.label}</Button>)
                    }
                </DialogActions>
            </Dialog>
            <div onClick={onBlockerClick} className={"blocker " + (state.blockCount > 0 ? "visible" : "hidden")}>
                <div className={"progress-container " + (state.showProgress ? "visible" : "hidden")}>
                    <RefreshIcon />
                    <span className="message">{state.progressMessage}</span>
                </div>
            </div>
        </OperationContext.Provider>
    );
}
