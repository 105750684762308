/// <reference path="../google.d.ts" />

import { IBasicAppSettings } from "../config/IBasicAppSettings";

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export class HttpError extends Error {

    protected _body: string;

    constructor(response: Response) {

        super();
        this.response = response;
    }

    async getBodyAsync() {

        if (!this._body)
            this._body = await this.response.text();
        return this._body;
    }

    readonly response: Response;
}

interface IHttpRequestOptions {
    useServerCache?: boolean;
    useClientCache?: boolean;
    data?: object;
    query?: object;
    customHeader?: Record<string, string>;
    isText?: boolean;
    useCaptcha?: boolean;
}

export abstract class BaseApiClient {

    protected _appSettings: IBasicAppSettings;

    constructor() {

        this.timeout = 60;
    }


    configure(appSetting: IBasicAppSettings) {
        this._appSettings = appSetting;
        if (appSetting.requestTimeout)
            this.timeout = appSetting.requestTimeout;
    }


    protected computeCaptchaAsync() {
        return new Promise<string>((res, rej) => {
            grecaptcha.enterprise.ready(async () => {
                try {
                    const token = await grecaptcha.enterprise.execute(this._appSettings.captchaKey, { action: 'submit' });
                    res(token);
                }
                catch (ex) {
                    rej(ex);
                }
            });
        });
    }
    protected requestUrl(path: string, options?: IHttpRequestOptions) {

        let url = this.endpoint + path;

        let query = "?";
        if (options?.query) {

            const formatValue = value => {
                if (value instanceof Date)
                    return formatValue(value.toISOString());
                return encodeURIComponent(value);
            }

            const extractQuery = (obj: Object, base: string) => {


                if (obj === undefined || obj === null)
                    return;


                if (Array.isArray(obj)) {


                    for (let j = 0; j < obj.length; j++) {

                        extractQuery(obj[j], base + "[" + j + "]")
                    }
                }
                else if (typeof obj == "object" && !(obj instanceof Date)) {

                    const keys = Object.keys(obj);

                    if (base.length > 0)
                        base += ".";

                    for (let i = 0; i < keys.length; i++) {

                        const value = (obj as any)[keys[i]];

                        extractQuery(value, base + keys[i]);
                    }
                }
                else {

                    if (query.length > 1)
                        query += "&";

                    query += base + "=" + formatValue(obj);
                }
            }

            extractQuery(options.query, "");
        }

        if (options?.useClientCache !== true) {
            if (query.length > 1)
                query += "&";
            query += "no-cache=1";
        }

        console.log(query);

        url += query;

        return url;
    }

    protected async requestJsonAsync<TResult>(path: string, method: HttpMethod = "GET", options?: IHttpRequestOptions) : Promise<TResult> {

        const url = this.requestUrl(path, options);

        let body: any = null;

        const headers: Record<string, string> = { ...options?.customHeader };

        if (options?.useServerCache === false)
            headers["Access-Control-Max-Age"] = "0";

        if (options?.data && method != "GET") {
            body = JSON.stringify(options?.data);
            headers["Content-Type"] = "application/json";
        }

        if (this.accessToken) {
            headers["Authorization"] = "Bearer " + this.accessToken;
        }

        this.fillHeaders(headers);

        let response = await new Promise<Response>(async (res, rej) => {

            let result: Response;

            const controller = new AbortController();

            const timeId = setTimeout(() => {
                if (!result) {
                    controller.abort();
                    rej(new Error("Request timeout"));
                }
     
            }, this.timeout * 1000)

            try {

                if (options?.useCaptcha) {
                    headers["X-Captcha"] = await this.computeCaptchaAsync();
                }

                result = await fetch(url, {
                    body,
                    method,
                    headers,

                    signal: controller.signal,

                    cache: options?.useClientCache === false ? "reload" : undefined
                });

                res(result);
            }

            catch (ex) {
                rej(ex);
            }
            finally {
                clearTimeout(timeId);
            }
        });


        
        if (!response.ok)
            throw new HttpError(response);

        const text = await response.text();

        if (options?.isText)
            return text as any;

        if (text == "")
            return null;

        return JSON.parse(text);
    }

    protected fillHeaders(headers: Record<string, string>) {

    }

    protected get accessToken() {
        return undefined;
    }

    abstract get endpoint();

    timeout: number;
}