import { ReactElement, useState } from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { ContentHostContext, IContentHostContext } from "../Hooks/ContentHostContext";
import { formatText } from "../Services/Localization";
import { useActions } from "../Hooks/ActionHook";
import { Routes } from "react-router-dom";
import { IContentProps } from "../Components/Content";
import { EventListContent } from "./EventList";
import { TicketListContent } from "./TicketList";
import { ProfileContent } from "./Profile";
import { Page } from "../Components/Page";
import { InstallBanner } from "../Components/InstallBanner";
import "./Home.scss";

export interface IHomeProps {
    routes: ReactElement[];
}

interface IContainerArea {
    main?: ReactElement<IContentProps>;
    over?: ReactElement<IContentProps>;
}

export function Home(props: IHomeProps) {

    const pages: ReactElement<IContentProps>[] = [TicketListContent, EventListContent, ProfileContent];

    const [containerAreas, setContainerAreas] = useState<IContainerArea>({ main: pages[0] });

    const action = useActions();

    const buttons = pages.map(a => <BottomNavigationAction
        key={a.props.name}
        onClick={() => action.loadContent(a, null, true)}
        label={formatText(a.props.title!)}
        value={a.props.name}
        icon={a.props.icon}
    />);

    const contentHost: IContentHostContext = {

        getContent: (area = "main") => containerAreas[area],
        setContent: async (content, area = "main") => {
            var newContent = {
                ...containerAreas,
                [area]: content
            };

            //console.log("setContent", area, content);

            if (area == "main") {
                newContent.over = undefined;
                //console.log("Over clear");
            }

            setContainerAreas(newContent);
        }
    }

    return <ContentHostContext.Provider value={contentHost}>

        <div className={"home" + (containerAreas.over ? " has-over" : "")}>
            <div className="content main">
                <Page>
                    {containerAreas.main}
                </Page>
            </div>
            <Paper elevation={3}>
                <BottomNavigation
                    showLabels
                    value={containerAreas.main?.props.name}>
                    {buttons}
                </BottomNavigation>
            </Paper>

        </div>

        {containerAreas.over && <div className="content over">
            <Page key={containerAreas.over?.props?.name}>
                {containerAreas.over}
            </Page>
        </div>
        }
        <InstallBanner/>
        <Routes>{props.routes}</Routes>

    </ContentHostContext.Provider>
}
