import SyncIcon from '@mui/icons-material/Sync';
import { useTheme } from '@mui/material';
import "./Loading.scss";

export function Loading() {

    const theme = useTheme();

    return <div className="loading-view">
        <SyncIcon sx={{ fill: theme.palette.primary.main }}/>
    </div>
}