import {IKeyword} from "../Components/KeywordSearch";
import {
    EventViewField,
    Guid,
    HttpError,
    IAppInstance,
    IEvent,
    IEventFilter,
    IListResult,
    ITicket,
    IVenue,
    apiClient
} from "boxol-front";
import {distict, groupBy} from "../Utils";
import {formatDateWeekDayNoYear, formatText} from "./Localization";
import {stringTable} from "./StringTable";
import PlaceIcon from '@mui/icons-material/Place';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import {currentTime, parseDate, truncateTime} from "./DateTime";
import {IStorage} from "../Abstraction/IStorage";
import {DbStorage} from "./DbStorage";
import {IDebugOptions, SearchKeywordType, isCachedResult} from "../Entites";
import {appSettings} from "./AppSettings";
import CircleIcon from '@mui/icons-material/Circle';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import React from "react";
import {LocalMovies} from "@mui/icons-material";

const TICKETS_CACHE = "tickets";
const EVENTS_CACHE = "events";
const VENUES_CACHE = "venues";
const USER_CACHE = "user";

export const IconForFilms = {
    "GraphicEq": <GraphicEqIcon/>,
    "Movies": <LocalMovies/>
}

class DataService {

    protected _events: IListResult<IEvent>;
    protected _venues: IListResult<IVenue>;
    protected _instance: IAppInstance;
    protected _tickets: IListResult<ITicket>;
    protected _updateTickets: Promise<any>;
    protected _updateEvents: Promise<any>;
    protected _updateVenues: Promise<any>;
    protected _cache: IStorage;

    protected async generateRandomTicketAsync() {

        let event: IEvent;

        while (true) {
            const eventIndex = Math.ceil((this._events.items.length - 1) * Math.random());
            event = this._events.items[eventIndex];
            if (event.tickets && event.tickets.length > 0)
                break;
        }
       
        const user = await this.getUserAsync();

        const ticket: ITicket = {
            id: crypto.randomUUID(),
            eventId: event.id,
            barcode: new Date().getTime().toString(),
            firstName: user.fullName,
            lastName: "",
            eventTicketId: event.tickets[0].id,
            isConfirmed: true,
            orderId: crypto.randomUUID(),
            orderPaymentId: new Date().getTime().toString(),
            seat: {
                blockId: 1,
                blockName: "Test",
                sectorName: "Test",
                seatName: "1",
                rowName: "A"
            }
        }

        return ticket;
    }

    async getTicketsAsync(refresh = false) {

        if (this._updateTickets)
            await this._updateTickets;

        else {

            await this.ensureEventsAsync(true, true);

            if (refresh || !this._tickets) {

                const debugOptions = this.getDebugOptions();
                if (appSettings.isDebug && debugOptions.generateRandomTickets) {
                    this._tickets = {
                        items: [],
                        total: 5,
                        lastUpdate: new Date()
                    };

                    for (let i = 0; i < 5; i++) {
                        this._tickets.items.push(await this.generateRandomTicketAsync());
                    }

                    return this._tickets.items;
                }

                this._updateTickets = this.execWithCacheAsync(() => apiClient.getTicketsAsync({
                    advertizeId: appSettings!.advertizeId,
                }, !refresh), TICKETS_CACHE, refresh, null, false);

                try {
                    this._tickets = await this._updateTickets;

                    for (const ticket of this._tickets.items) {
                        if (ticket.barcode)
                            await this.getBarcodeAsync(ticket.barcode);
                        await this.getBarcodeAsync(ticket.orderId);
                    }
                } finally {
                    this._updateTickets = undefined;
                }
            }
        }

        return this._tickets?.items ?? [];
    }

    async configureAsync() {

        const instance = appSettings.instance;

        if (instance.appId != this._instance?.appId ||
            instance.lang != this._instance?.lang) {

            this._instance = {...instance};

            this._cache = new DbStorage("data", this._instance.appId);

            await this._cache.openAsync();

            if (appSettings.isDebug)
                await this.setDebugOptionsAsync(this.getDebugOptions());
        }

        const options = this.getDebugOptions();
        if (options.mockUserId)
            apiClient.mockUserId = options.mockUserId;
    }

    async setDebugOptionsAsync(options: IDebugOptions) {
        localStorage.setItem("debug", JSON.stringify(options));
        const newUserId = options.useMockUser ? options.mockUserId : undefined;
        if (apiClient.mockUserId && newUserId != apiClient.mockUserId)
            await this.clearSessionAsync();
        apiClient.mockUserId = newUserId;
    }

    canCancelOrder(event: IEvent, tickets: ITicket[]) {

        const flag = event.flags?.find(a => a.name == "ANNULLABILE");
        if (!flag)
            return false;
        if (!flag.value)
            return true;
        var now = currentTime();
        var maxDate = parseDate(event.startTime).getTime() - (flag.value * 1000 * 60);
        if (now.getTime() >= maxDate)
            return false;
        return true;
    }

    getEventTagInfo(event: IEvent) {

        if (event.tags && appSettings.tags) {
            for (const tag in appSettings.tags) {

                if (event.tags.indexOf(tag) != -1)
                    return {
                        ...appSettings.tags[tag],
                        tag
                    }
            }
        }
    }

    getDebugOptions(): IDebugOptions {
        var curDebug = localStorage.getItem("debug");
        if (!curDebug)
            return {} as IDebugOptions;
        return JSON.parse(curDebug);
    }


    getSeatStateCompactAsync(eventId: Guid, refresh = false) {

        return apiClient.getSeatStateCompactAsync(eventId, !refresh);
    }

    async getEventByIdAsync(eventId: Guid, refresh = false) {

        if (!refresh) {
            await this.ensureEventsAsync();
            const result = this._events?.items.find(a => a.id == eventId);
            if (result)
                return result;
            //TODO: check if reinsert this
            //await this.ensureEventsAsync(false, false);
        }

        const result = (await apiClient.getEventsAsync({
            eventIds: [eventId],
            includeNotFiscal: appSettings.includeNotFiscal,
            fields: [EventViewField.Tickets, EventViewField.Movie, EventViewField.Description, EventViewField.Availability]
        }, false)).items;

        const cacheEvent = this._events?.items.find(a => a.id == eventId);
        if (result.length > 0 && cacheEvent)
            result[0].mode = cacheEvent.mode;

        return this.getValidEvents(result)[0];
    }

    async logoutAsync() {

        await new Promise<void>(res => {
            const iframe = document.createElement("iframe");
            iframe.onload = () => {
                iframe.remove();
                res();
            };
            iframe.src = appSettings.boxolWebRoot + "en/logoff";
            iframe.style.display = "none";
            document.body.appendChild(iframe);
        });

        await this.clearSessionAsync();
    }

    async clearSessionAsync() {
        localStorage.removeItem("user");
        if (this._cache)
            await this._cache.clearAsync();
    }

    async clearAllAsync() {
        if (window.caches) {
            for (const key of await window.caches.keys())
                await window.caches.delete(key);
        }
        localStorage.clear();
        sessionStorage.clear();
    }

    async getUserAsync() {

        var curUser = localStorage.getItem("user");

        try {
            const response = await this.execWithCacheAsync(() => apiClient.getUserAsync(), USER_CACHE, true, null, true);

            if (curUser && curUser != response.id) {

                localStorage.setItem("user", response.id);
                await this._cache.clearAsync();
            }
            return response;

        } catch (ex) {
            console.error("getUserAsync", ex);
            if (ex instanceof HttpError && ex.response.status == 401)
                await this.clearSessionAsync();
            return null;
        }
    }

    async getBarcodeAsync(barcode: string) {

        const url = "https://barcode-generator.boxol.it/azteccode/" + encodeURIComponent(barcode);

        if (!window.caches)
            return url;

        const cache = await window.caches.open("barcodes");

        if ((await cache.keys(url)).length == 0)
            await cache.add(url);

        const match = await cache.match(url);
        if (match)
            return URL.createObjectURL(await match.blob());

    }

    protected getValidEvents(events: IEvent[], date?: Date) {

        if (!date)
            date = currentTime();

        const result: IEvent[] = [];
        for (const event of events) {

            if (!event.isActive ||
                (event.visibleFrom && (date.getTime() < parseDate(event.visibleFrom).getTime())) ||
                (event.visibleTo && (date.getTime() > parseDate(event.visibleTo).getTime()))) {
                continue;
            }

            if (event.tags && event.tags.includes("abbonamento")) {
                continue;
            }
            

            const tickets = event.tickets.filter(a =>
                a.isActive &&
                (!a.visibleFrom || (date.getTime() >= parseDate(a.visibleFrom).getTime())) &&
                (!a.visibleTo || (date.getTime() <= parseDate(a.visibleTo).getTime())));

            if (tickets.length == 0 && !appSettings.includeNotFiscal)
                continue; 

            result.push({
                ...event,
                tickets: tickets ?? []
            });
        }
        return result;
    }

    getValidTickets(event: IEvent) {
        return event?.tickets?.filter(a => a.freeSeats > 0 && a.isActive);
    }

    getMapFileUrl(cmpCode: string) {
        return `https://nts100-maps-repository.s3.eu-west-1.amazonaws.com/prod/${cmpCode}.mview`;
    }

    getAppResourceUrl(res: string) {

        return apiClient.getResourceUrl(res);
    }

    async getVenueByPlaceAsync(place: string) {
        await this.ensureVenuesAsync();
        const venue = this._venues?.items.find(a => a.place == place || a.space == place);
        if (venue?.alias)
            return this.getVenueByPlaceAsync(venue.alias);
        return venue;
    }

    async getEventsAsync(refresh = false) {

        await this.ensureEventsAsync(!refresh, true, true);

        return this.getValidEvents(this._events.items);
    }

    getLastTicketsUpdate() {
        return new Date(this._tickets?.lastUpdate);
    }

    getEventVenue(event: IEvent) {
        if (!event)
            return "";
        return event.movie?.venue ?? formatText(event.place);
    }

    getEventTitle(event: IEvent) {
        if (!event)
            return "";
        return event.movie?.title ?? formatText(event.title);
    }

    filterEvents(events: IEvent[], words: IKeyword<SearchKeywordType>[]) {

        let curEvents = events;

        if (words.length > 0) {

            var groups = groupBy(words, a => a.type);

            for (const group of groups) {
                switch (group.key) {
                    case SearchKeywordType.Date:
                        curEvents = curEvents.filter(a => group.values.some(b => truncateTime(a.startTime).getTime() == b.value.getTime()));
                        break;
                    case SearchKeywordType.Title:
                        curEvents = curEvents.filter(a => group.values.some(b => dataService.getEventTitle(a) == b.text));
                        break;
                    case SearchKeywordType.Venue:
                        curEvents = curEvents.filter(a => group.values.some(b => dataService.getEventVenue(a) == b.text));
                        break;
                    case SearchKeywordType.Director:
                        curEvents = curEvents.filter(a => group.values.some(b => a.movie?.director == b.text));
                        break;
                    case SearchKeywordType.Tag:
                        curEvents = curEvents.filter(a => group.values.some(b => a.tags?.includes(b.value)));
                        break;
                }

                if (curEvents.length == 0)
                    break;
            }
        }
        return curEvents;
    }

    createSearchKeyword(events: IEvent[]) {

        const result: IKeyword<SearchKeywordType>[] = [];

        const directors = distict(events.map(a => a.movie?.director).filter(a => a?.length > 0), a => a, a => a);
        const titles = distict(events.map(a => dataService.getEventTitle(a)).filter(a => a != null), a => a, a => a);
        const venues = distict(events.map(a => dataService.getEventVenue(a)).filter(a => a != null), a => a, a => a);
        const dates = distict(events.map(a => truncateTime(a.startTime)), a => a.getTime(), a => a);
        const tags = distict(events.map(a => dataService.getEventTagInfo(a)).filter(a => a != null), a => a.tag, a => a);

        console.log(appSettings)

        directors.forEach(a => result.push(({
            type: SearchKeywordType.Director,
            text: a,
            icon: <PhotoCameraFrontIcon/>
        })));

        titles.forEach(a => result.push(({
            type: SearchKeywordType.Title,
            text: a,
            icon: IconForFilms[appSettings.theme.eventIcon]
        })));

        venues.forEach(a => result.push(({
            type: SearchKeywordType.Venue,
            text: a,
            icon: <PlaceIcon/>
        })));

        dates.forEach(a => result.push(({
            type: SearchKeywordType.Date,
            text: formatDateWeekDayNoYear(a),
            value: a,
            icon: <CalendarTodayIcon/>
        })));

        tags.forEach(a => result.push(({
            type: SearchKeywordType.Tag,
            text: formatText(a.name),
            value: a.tag,
            icon: <CircleIcon style={{color: a.color}}/>
        })));


        result.sort((a, b) => a.text.localeCompare(b.text));

        return result;
    }

    async cancelOrderAsync(orderId: Guid, notes: string) {

        const query = new URLSearchParams();
        query.set("Notes", notes);
        query.set("OrderId", orderId);
        query.set("RedirectToOrdersList", "True");

        const url = `${appSettings.boxolWebRoot}${stringTable.activeLanguage.toLowerCase()}/cancel-order`;

        const body = query.toString()

        const response = await fetch(url, {
            method: "POST",
            body: body,
            redirect: 'follow',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });

        if (response.ok) {
            var text = await response.text();
            console.log(text);
            return true;
        }
        return false;
    }

    protected async execWithCacheAsync<T>(action: () => Promise<T>, key: string, refresh: boolean, maxAgeSec?: number, useCachOnError = false) {

        try {

            if (!refresh) {
                const hasCache = await this._cache.hasKeyAsync(key);
                if (hasCache) {
                    const result = await this._cache.getItemAsync<T>(key);
                    if (isCachedResult(result) && maxAgeSec) {
                        const cacheTime = parseDate(result.lastUpdate).getTime();
                        const ellapsed = (new Date().getTime() - cacheTime) / 1000;
                        if (ellapsed > maxAgeSec)
                            refresh = true;
                    }
                    if (!refresh)
                        return result;
                }
            }

            const result = await action();
            await this._cache.setItemAsync(key, result);
            return result;
        } catch (ex) {

            if (ex instanceof HttpError && ex.response.status == 401)
                throw ex;

            if ((refresh && !useCachOnError) || !(await this._cache.hasKeyAsync(key)))
                throw ex;

            return await this._cache.getItemAsync<T>(key);
        }
    }


    protected async ensureEventsAsync(useClientCache = true, useServerCache = true, updateClientCache = false) {

        if (this._updateEvents)
            await this._updateEvents;

        else {

            if (!useClientCache || !this._events || updateClientCache) {

                const filter: Partial<IEventFilter> = {
                    advertizeId: appSettings.advertizeId,
                    includeNotFiscal: appSettings.includeNotFiscal,
                    startTime: appSettings.filterStartDate,
                    appId: appSettings.filterByAppId ? apiClient.instance.appId : undefined,
                    fields: [EventViewField.Tickets, EventViewField.Movie, EventViewField.Description, EventViewField.Availability]
                };

                this._updateEvents = new Promise<IListResult<IEvent>>(async res => {

                    const result = await this.execWithCacheAsync(() =>
                        apiClient.getEventsAsync(filter, useServerCache),
                        EVENTS_CACHE, !useClientCache, 5 * 60, true);

                    if (appSettings.advertize) {

                        for (const advId in appSettings.advertize) {

                            const info = appSettings.advertize[advId];

                            filter.advertizeId = advId;

                            const newEvents = await this.execWithCacheAsync(() =>
                                apiClient.getEventsAsync(filter, useServerCache),
                                EVENTS_CACHE + "_" + advId, !useClientCache, 5 * 60, true);

                            for (const event of newEvents.items)
                                event.mode = info.mode;

                            result.items.push(...newEvents.items);
                            result.total += newEvents.total;
                        }
                    }

                    res(result);
                });

                try {

                    this._events = await this._updateEvents;
                } finally {
                    this._updateEvents = undefined;
                }
            }
        }
    }

    protected async ensureVenuesAsync(refresh = false) {

        if (this._updateVenues)
            await this._updateVenues;

        else {

            if (!this._venues) {

                this._updateVenues = this.execWithCacheAsync(() =>
                        apiClient.getVenuesAsync({}),
                    VENUES_CACHE, refresh, 3600, true);

                try {
                    this._venues = await this._updateVenues;

                } finally {
                    this._updateVenues = undefined;
                }

            }
        }
    }

    getAppVersion() {
        return localStorage.getItem("curVersion");
    }

    async checkNewVersionAsync() {
        try {
            const version = await (await fetch(`${process.env.PUBLIC_URL}/version?no-cache=1&t=${new Date().getTime()}`)).json() as string;
            const curVersion = this.getAppVersion();

            const isChanged = curVersion && curVersion != version;

            if (!curVersion || isChanged)
                localStorage.setItem("curVersion", version);

            if (isChanged) {

                if (window.caches) {
                    for (const key of await caches.keys()) {
                        if (key.startsWith("boxol-events"))
                            await caches.delete(key);
                    }
                }
                
                if (window.indexedDB) {
                    for (const db of await window.indexedDB.databases()) {
                        await window.indexedDB.deleteDatabase(db.name);
                    }
                }

                await this.clearSessionAsync();

                window.location.reload();
            }
        } catch (ex) {
            console.log("checkNewVersionAsync", ex);
        }
    }


}

export const dataService = new DataService();