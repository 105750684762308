import { Fragment, useState } from "react";
import { Box, Button, Container, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { ITicket, IVenue } from "boxol-front";
import { dataService } from "../Services/DataService";
import { TicketListContent } from "./TicketList";
import { createContent } from "../Components/Content";
import { MapView } from "@attractive/map-viewer";
import "./TicketsMap.scss";
import { IBaseTicketsProps } from "./TicketsDetails";
import MapViewer, { IMapViewerProps } from "../Components/MapViewer";
import { formatText } from "../Services/Localization";
import { geoMapService } from "../Services/GeoMapService";
import StaticGeoMap from "../Components/StaticGeoMap";
import Field from "../Components/Field";


interface ITicketsMapProps extends IBaseTicketsProps {
    venue?: IVenue;
}

function TicketsMap(props: ITicketsMapProps) {

    const [mapView, setMapView] = useState<MapView>();

    const venueCenter = {
        latitude: props.venue?.latitude,
        longitude: props.venue?.longitude,
    }

    const options: IMapViewerProps["options"] = {
        styles: {
            "block": {
                text: {
                    fontSize: 19
                },
                strokeWidth: 1,
                strokeColor: "#999"
            },
            "seat": {
                text: {
                    fontSize: 12,
                    color: "#fff"
                },
                fillColor: "#ccc"
            }
        },
    };

    const findSeat = (mapView: MapView, ticket: ITicket) => {

        const block = mapView.map.blocks.find(a => a.data.id == ticket.seat.blockId || (a.data.mergeBlockIds && a.data.mergeBlockIds.indexOf(ticket.seat.blockId) != -1));
        if (block != null) {
            const seat = block.seats.find(a =>
                a.data.row.toLowerCase() == ticket.seat.rowName.toLowerCase() &&
                a.data.seat.toLowerCase() == ticket.seat.seatName.toLowerCase());
            return seat;
        }
    }

    const zoomToSeat = (ticket: ITicket) => {

        if (!mapView)
            return;

        const seat = findSeat(mapView, ticket);
        if (seat) {

            //const center = seat.block.geometry.transform.transformPoint(seat.offset);
            mapView.animateView(seat.block.geometry.bounds, 0.3);
            //mapView.animateOrigin(center, 0.3);
        }

    }

    const onMapReady = (mapView: MapView) => {

        setMapView(mapView);

        mapView.map.beginUpdate();

        for (const ticket of props.tickets) {
            const seat = findSeat(mapView, ticket);
            if (seat) {
                seat.style = {
                    fillColor: "#1976D3"
                }
            }
        }

        mapView.map.notifyChanged();

        mapView.map.endUpdate();

        mapView.mobileMode = document.body.clientWidth < 600 && navigator.maxTouchPoints > 0;
    }

    return <Container>
        <Typography variant="h5">{props.event.place}</Typography>
        <List className="seat-list">
            {props.tickets.map(a => <ListItem key={a.barcode} sx={{ p: 0 }}>
                <ListItemButton onClick={() => zoomToSeat(a)}>
                    <ListItemText primary={
                        <Fragment>
                            <span className="sector">{a.seat.sectorName}</span>
                            <Field label="row">{a.seat.rowName}</Field>
                            <Field label="seat">{a.seat.seatName}</Field>

                        </Fragment>
                    }></ListItemText>
                </ListItemButton>
            </ListItem>)
            }
        </List>

        {props.venue.spaceCmpCode &&
            <MapViewer src={dataService.getMapFileUrl(props.venue.spaceCmpCode)} options={options} onReady={onMapReady} />
        }

        <Box className="venue">
            <Typography variant="h6">{props.venue.location}</Typography>
            <Typography variant="subtitle1">{props.venue.space}</Typography>
            <StaticGeoMap options={{
                center: venueCenter,
                zoomLevel: 15,
                size: {
                    width: window.innerWidth,
                    height: Math.round(window.innerWidth * 8 / 16)
                },
                pins: [{
                    center: venueCenter,
                    name: "",
                    icon: 113
                }]
            }} />
            <address>
                <Typography>{props.venue.fullAddress}</Typography>
            </address>
            <div className="actions">
                <Button variant="text" onClick={() => geoMapService.openMaps(venueCenter)}>{formatText("show-in-maps")}</Button>
                <Button variant="text" onClick={() => geoMapService.openDirections(venueCenter)} >{formatText("directions")}</Button>
            </div>
        </Box>
    </Container>;

}

export const TicketsMapContent = createContent<ITicketsMapProps>({

    body: args => <TicketsMap {...args} />,
    route: "/tickets/:eventId/map",
    name: "tickets-map",
    title: "seats",
    parent: TicketListContent,
    authorize: true,

    async loadAsync(args) {

        if (!args.tickets) {
            const tickets = await dataService.getTicketsAsync(false);
            args.tickets = tickets.filter(a => a.eventId == args.eventId);
        }

        if (!args.event)
            args.event = (await dataService.getEventByIdAsync(args.eventId))!;

        if (!args.venue)
            args.venue = (await dataService.getVenueByPlaceAsync(dataService.getEventVenue(args.event)))!;

        return true;
    }
});