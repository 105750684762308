import { isAndroid, isAppleMobile, isStandalone } from "../Helpers/Devices";
import { formatText } from "../Services/Localization";
import Ios01 from "../Assets/help/ios-install-01.png";
import Ios02 from "../Assets/help/ios-install-02.png";
import { Checkbox, FormControlLabel, FormGroup, IconButton, Typography } from "@mui/material";
import { ChangeEvent, Fragment, MouseEvent, useEffect, useRef, useState } from "react";
import "./InstallBanner.scss";
import { dataService } from "../Services/DataService";
import CloseIcon from '@mui/icons-material/Close';
import { theme } from "../Services/Theme";

export function InstallBanner() {

    const [state, setState] = useState<"closed" | "semi-open" | "open">("closed");

    const [refresh, setRefresh] = useState(false);

    const modalRef = useRef<HTMLDivElement>();

    const dontShowAgain = localStorage.getItem("showInstallApp") == "false";

    const notRender = dontShowAgain || isStandalone() || !isAppleMobile();

    useEffect(() => {

        if (!modalRef.current) 
            return;


        if (state == "semi-open") {
            const head = modalRef.current.querySelector("header");
            console.log(head.clientHeight);
            modalRef.current.style.height = head.clientHeight + "px";
        }

        if (state == "closed") {
            modalRef.current.style.height = "0px";
        }


        if (state == "open") {
            modalRef.current.style.height = window.innerHeight + "px";
        }

    }, [state, modalRef.current]);


    useEffect(() => {

        if (notRender)
            return;

        setTimeout(() => {

            setState("semi-open");

        }, 2000)
    }, []);

    const closeBanner = (ev: MouseEvent) => {
        setState("closed");
        ev.preventDefault();
        ev.stopPropagation();
    }

    const openBanner = (ev: MouseEvent) => {

        if ((ev.target as HTMLElement).nodeName != "INPUT")
            setState("open");
    }

    const setDontShowAgain = (ev: ChangeEvent<HTMLInputElement>, value: boolean) => {

        localStorage.setItem("showInstallApp", JSON.stringify(!value));
        setRefresh(!refresh);

    }

    if (notRender)
        return null;

    return <div
        ref={modalRef}
        className={"install-banner " + state}
    >
        <div className="content">
            <header onClick={openBanner}>
                <IconButton className="closeButton" onClick={closeBanner}><CloseIcon /></IconButton>
                <div className="skeleton">
                </div>
                <div className="message">
                    <img className="icon" src={dataService.getAppResourceUrl(theme.current.icon.src)} />
                    <Typography variant="body1">{formatText("msg-install-app", isAndroid() ? "Android" : "IOS")}</Typography>
                </div>
                <FormGroup className="dont-show">
                    <FormControlLabel control={<Checkbox checked={dontShowAgain} onChange={setDontShowAgain} />} label={formatText("dont-show-again")} />
                </FormGroup>
            </header>
            <section>
                {isAppleMobile() && <Fragment>
                    <div className="step">
                        <Typography variant="h6">{formatText("step", "1")}</Typography>
                        <img src={Ios01} />
                    </div>
                    <div className="step">
                        <Typography variant="h6">{formatText("step", "2")}</Typography>
                        <img src={Ios02} />
                    </div>

                </Fragment>}
            </section>
        </div>
        
    </div>
}