import { cloneElement } from "react";
import { Guid, IEvent, ITicket } from "boxol-front";
import { dataService } from "../Services/DataService";
import { IContentTitle, createContent } from "../Components/Content";
import { ContentList } from "../Components/ContentList";
import { TicketsCodeContent } from "./TicketsCode";
import { TicketsMapContent } from "./TicketsMap";
import { TicketsDownloadContent } from "./TicketsDownload";
import { useActions } from "../Hooks/ActionHook";
import "./TicketsDetails.scss";


export interface IBaseTicketsProps extends IContentTitle {
    eventId: Guid;
    tickets?: ITicket[];
    event?: IEvent;
}

interface ITicketsDetailsProps extends IBaseTicketsProps {
    activeContent?: string;
}

function TicketsDetails(props: ITicketsDetailsProps) {

    const actions = useActions();

    const onContentChanged = value => {
        actions.ticketsDetails(props.eventId, value, true);
    }

    const newProps = { hasHost: true, args: props };

    const hasVenue = props.event.venue != null;
    
    return <ContentList active={props.activeContent} onActiveChanged={onContentChanged}>
        {cloneElement(TicketsCodeContent, newProps)}
        {hasVenue && cloneElement(TicketsMapContent, newProps)}
        {cloneElement(TicketsDownloadContent, newProps)}
        
    </ContentList> 
}

export const TicketsDetailsContent = createContent<ITicketsDetailsProps>({

    body: args => <TicketsDetails {...args} />,
    route: "/tickets/:eventId/:activeContent",
    name: "ticket-details",
    parent: "my-tickets",
    authorize: true,
    hasCustomAppBar: true,
    area: "over",

    async loadAsync(args) {

        if (!args.tickets) {
            const tickets = await dataService.getTicketsAsync(false);
            args.tickets = tickets.filter(a => a.eventId == args.eventId);
        }

        if (!args.event) 
            args.event = (await dataService.getEventByIdAsync(args.eventId))!;

        args.title = dataService.getEventTitle(args.event);

        return args.tickets?.length > 0 && args.event != null;
    }
});