import { ReactElement, useEffect, useState } from "react";
import { IUserContext, UserContext } from "../Hooks/UserContext";
import { dataService } from "../Services/DataService";
import { Loading } from "./Loading";
import MenuIcon from '@mui/icons-material/Menu';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import { AppBarContext, IAppBarContext, IAppBarState } from "../Hooks/AppBarContext";
import { AppBar, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar, Typography, useTheme } from "@mui/material";
import { formatText } from "../Services/Localization";
import { useNetwork } from "../Hooks/NetworkHook";
import { appSettings } from "../Services/AppSettings";
import { IOperation, useOperation } from "../Hooks/OperationContext";
import { useActions } from "../Hooks/ActionHook";
import "./Page.scss";
import ItFlag from "../Assets/it.svg";
import EnFlag from "../Assets/gb.svg";
import { mapObject } from "../Utils";
import { LoadingState } from "./Content";
import { usePageView } from "../Hooks/PageViewHook";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { IUserProfile } from "boxol-front";
import { theme } from "../Services/Theme";

export interface IPageProps {
    children?: ReactElement;
}

export function Page(props: IPageProps) {

    const muiTheme = useTheme();

    const network = useNetwork();

    const operation = useOperation();

    const actions = useActions();

    usePageView();

    let [appBarState, setAppBarState] = useState({ loading: true } as IAppBarState)

    const [drawerOpen, setDrawerOpen] = useState(false);

    const [user, setUser] = useState<IUserProfile | "no-user">();

    const [loadingState, setLoadingState] = useState<LoadingState>("none");


    const userContext: IUserContext = {
        clear: () => {
            setUser(undefined);
            dataService.clearSessionAsync();
        },
        user
    };

    const appBar: IAppBarContext = {
        setState(state: IAppBarState) {
            appBarState = {
                ...appBarState,
                ...state
            }
            setAppBarState(appBarState);
        }
    }

    useEffect(() => {

        if (user) {
            if (loadingState != "loaded")
                setLoadingState("loaded");
            return;
        }

        let loadOp: IOperation;

        if (appSettings.globalLoading)
            loadOp = operation.begin({ name: "Loading page" });

        setLoadingState("loading");

        network(async () => {

            try {

                const curUser = await dataService.getUserAsync();

                setUser(curUser ?? "no-user");

                setLoadingState("loaded");
            }
            finally {
                if (appSettings.globalLoading)
                    operation.end(loadOp);
            }

        },);

    }, []);


    if (loadingState != "loaded")
        return appSettings.globalLoading ? null : <Loading />;

    const body = props.children;

    const onMainIconClick = () => {
        if (appBarState.backAction)
            appBarState.backAction();
        else
            setDrawerOpen(true);
    }

    const contentName = body?.props.name;

    const headerSrc = theme.current.header?.src ? dataService.getAppResourceUrl(theme.current.header.src) : undefined;

    const langList = [
        { lang: "IT", desc: "Italiano", icon: <img src={ItFlag} /> },
        { lang: "EN", desc: "English", icon: <img src={EnFlag} /> }]

    const curUser = user && user != "no-user" ? user : { fullName: "not-logged-in", username: "" } as IUserProfile;

    const titleColor = theme.current.title?.color ? theme.current.palette[theme.current.title.color] : "";

    return <div className={"page" + (appBarState.loading ? " loading " : " ") + contentName + " " + appSettings.instance?.appId} key={contentName}>
        <Drawer
            className="main-drawer"
            anchor="right"
            open={drawerOpen}
            onClose={() => setDrawerOpen(!drawerOpen)}
        >
            {headerSrc && <header style={{
                backgroundColor: muiTheme.palette.primary.main,
                color: muiTheme.palette.primary.contrastText
            }}>
                <img alt={formatText(theme.current.name)} src={headerSrc} />
                <Typography className="user">{curUser.fullName}</Typography>
            </header>}
            <section className="main">
                <List>
                    <ListSubheader>{formatText("language")}</ListSubheader>
                    {langList.map(a =>
                        <ListItem disablePadding key={a.lang}>
                            <ListItemButton onClick={() => actions.changeLanguage(a.lang)}>
                                <ListItemIcon>{a.icon}</ListItemIcon>
                                <ListItemText primary={a.desc} />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
                {appSettings.relatedApps && <List>

                    <ListSubheader>{formatText("go-to")}</ListSubheader>
                    {mapObject(appSettings.relatedApps, (name, desc) =>
                        <ListItem disablePadding key={name}>
                            <ListItemButton onClick={() => actions.changeApp(name as string)}>
                                <ListItemIcon><CalendarTodayIcon /></ListItemIcon>
                                <ListItemText primary={formatText(desc)} />
                            </ListItemButton>
                        </ListItem>
                    )}
         
                </List>}
                {appSettings.isDebug && <List><ListItem disablePadding>
                    <ListItemButton onClick={() => { setDrawerOpen(false); actions.debugOptions() }}>
                        <ListItemIcon><SettingsIcon /></ListItemIcon>
                        <ListItemText primary="Debug" />
                    </ListItemButton>
                </ListItem></List>}
                <Typography variant="body2" className="version">v. {dataService.getAppVersion()}</Typography>
            </section>
            <footer>
                <div className="content">
                    <Typography className="user">{curUser.fullName}</Typography>
                    <Typography variant="body2" className="user-email">{curUser.username}</Typography>
                </div>
                <IconButton onClick={() => actions.logout()}><LogoutIcon /></IconButton>
            </footer>
        </Drawer>
        <UserContext.Provider value={userContext}>
            <AppBarContext.Provider value={appBar}>

                <AppBar position="static">
                    <Toolbar>
                        {theme.current.appBarIcon && <img src={dataService.getAppResourceUrl(theme.current.appBarIcon.src)} />}
                        <Typography color={titleColor} variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {formatText(theme.current.title?.isStatic ? theme.current.title.text : appBarState?.title!)}
                        </Typography>
                        <IconButton
                            size="large"
                            aria-label="menu"
                            sx={{color: "inherit"}}
                            onClick={onMainIconClick}
                        >
                            {appBarState.backAction ? <BackIcon /> : <MenuIcon />}

                        </IconButton>
                    </Toolbar>
                    {appBarState.custom}
                </AppBar>

                {body}

            </AppBarContext.Provider>
        </UserContext.Provider>
    </div>;
}