import { Guid } from "boxol-front";

export enum SearchKeywordType {
    Date,
    Venue,
    Title,
    Director,
    Tag
}

export interface ICachedResult {
    lastUpdate: string | Date;
}

export interface IDebugOptions {
    mockUserId: Guid;
    useMockUser: boolean;
    mockDate: Date | string;
    useMockDate: boolean;
    generateRandomTickets: boolean;
    showMap: boolean;
}
export interface IVenue {
    space: string;
    spaceCode: string;
    spaceCmpCode: string;
    location: string;
    place: string;
    fullAddress: string;
    latitude: number;
    longitude: number;
    alias?: string;
}

export function isCachedResult(obj: any): obj is ICachedResult {

    return obj && typeof obj == "object" && "lastUpdate" in obj;
}