
declare global {
    var chrome: any;
}

export function testPostMessage(type: string, data?: any) {

    if ("chrome" in window && window.chrome?.webview) {

        window.chrome.webview.postMessage({
            type: type,
            data: data
        });
    }
}

export function testAttach() {

}