import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './Components/App';
import { appSettings, loadAppSettingsAsync } from './Services/AppSettings';
import { EventListContent } from './Pages/EventList';
import { TicketListContent } from './Pages/TicketList';
import { registerContent } from './Helpers/ContentManager';
import { EventDetailsContent } from './Pages/EventDetails';
import { TicketsMapContent } from './Pages/TicketsMap';
import { ProfileContent } from './Pages/Profile';
import { TicketsDetailsContent } from './Pages/TicketsDetails';
import { monitorHistory } from './Services/HistoryMonitor';
import { dataService } from './Services/DataService';
import { NotFound } from './Components/NotFound';
import { testAttach } from "boxol-front";
import { DebugOptionsContent } from './Pages/DebugOptions';
import "./Lang/IT";
import "./Lang/EN";
import "./Index.scss";

async function runAsync() {

    monitorHistory();

    testAttach();

    console.log(process.env);

    window.addEventListener("error", er => {
        console.error("UNHANDLED: ", er);
    });

    window.addEventListener("unhandledrejection", er => {

        console.error("UNHANDLED ASYNC: ", er.reason);
        console.error(er.reason?.stack);
    });

    await dataService.checkNewVersionAsync();

    try {
        if ('serviceWorker' in navigator) {
            await navigator.serviceWorker.register(process.env.PUBLIC_URL + "/ServiceWorker.js");
        }
    }
    catch (ex) {
        console.warn(ex);
    }

    registerContent(EventListContent);
    registerContent(TicketListContent);
    registerContent(TicketsDetailsContent);
    registerContent(EventDetailsContent);
    registerContent(TicketsMapContent);
    registerContent(ProfileContent);
    registerContent(DebugOptionsContent);

    await loadAppSettingsAsync();

    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );

    root.render(
        <React.StrictMode>
            {!appSettings ? <NotFound /> : <App />}
        </React.StrictMode>
    );
}


runAsync();

