import { useEffect, useRef } from "react";

export interface IHtmlProps {
    children: string;
}

export default function Html(props: IHtmlProps) {

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (divRef.current) {
            divRef.current.innerHTML = props.children;
        }
    }, [divRef, props.children]);

    return <div className="html" ref={divRef}></div>;
}