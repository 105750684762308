import { appSettings } from "./AppSettings";
import { dataService } from "./DataService";

export function currentTime() {

    const options = dataService.getDebugOptions();
    if (options.useMockDate && options.mockDate && appSettings.isDebug)
        return parseDate(options.mockDate);
    return new Date();
}

export function parseDate(date: Date | string) {
    return typeof (date) == "string" ? new Date(date) : date;
}

export function truncateTime(date: Date | string) {
    return new Date(parseDate(date).toDateString());
}