import { IStaticMapOptions, geoMapService } from "../Services/GeoMapService";

export interface IStaticGeoMapProps {
    options: IStaticMapOptions;
    title?: string;
}

export default function StaticGeoMap(props: IStaticGeoMapProps) {

    var map = geoMapService.staticMap(props.options);
    return <img alt={props.title} className="geo-map" src={map}/>
}