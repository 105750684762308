import { ReactNode, createContext, useContext } from "react";

export type AlertType = "success" | "warning" | "error" | "info";

interface IOperationOptions {
    showProgress?: boolean;
    name: string;
}

export interface IAction {
    label: string;
    action?: () => Promise<void>
}

export interface IOperation {
    name?: string;
    id: number;
}

export interface IOperationContext {

    begin(options?: IOperationOptions): IOperation;

    end(op?: IOperation): void;

    showAlert(body: ReactNode, type: AlertType, duration?: number) : void;

    showErrorAsync<T extends { [key: string]: IAction }>(body: ReactNode, actions?: T): Promise<keyof T>;

    showMessageAsync<T extends { [key: string]: IAction }>(body: ReactNode, title: string, actions?: T): Promise<keyof T>;

    confirmAsync(body: ReactNode): Promise<boolean>;
}

export const OperationContext = createContext({} as IOperationContext);


export function useOperation() {
    return useContext(OperationContext);
}