import { IStorage } from "../Abstraction/IStorage";

export class DbStorage implements IStorage {

    private _database: IDBDatabase;
    private _name: string;
    private _storeName: string;

    constructor(name: string, storeName = "object") {
        this._name = name;
        this._storeName = storeName;
    }


    async openAsync() {

        const attach = () => {
            this._database.onclose = () => {
                this._database = null;
            }
        }

        console.log("Opening DB");

        return new Promise<boolean>((res, rej) => {

            const curVer = localStorage.getItem(this._name + ":version");

            const request = indexedDB.open(this._name, curVer ? parseInt(curVer) : 2);

            request.onerror = () => rej();

            request.onsuccess = async () => {

                console.log("DB Open");

                this._database = request.result;

                if (!this._database.objectStoreNames.contains(this._storeName)) {
                    localStorage.setItem(this._name + ":version", (this._database.version + 1).toString());
                    this._database.close();
                    this._database = null;
                    console.log("Executing Upgrade");
                    res(await this.openAsync());
                    return;
                }
                attach();
                res(true);
            }

            request.onupgradeneeded = () => {

                this._database = request.result;
                if (!this._database.objectStoreNames.contains(this._storeName))
                    this._database.createObjectStore(this._storeName);
                console.log("Upgrade Ended: ", this._database.version);
            }
        });
    }

    protected async ensureOpenAsync() {
        if (!this._database)
            await this.openAsync();
    }

    protected async openReadWriteAsync() {
        await this.ensureOpenAsync();
        return this._database.transaction(this._storeName, "readwrite").objectStore(this._storeName);
    }

    protected async openReadAsync() {
        await this.ensureOpenAsync();
        return this._database.transaction(this._storeName, "readonly").objectStore(this._storeName);
    }


    setItemAsync(key: string, value: any) {

        return new Promise<void>(async (res, rej) => {

            const request = (await this.openReadWriteAsync()).put(JSON.stringify(value), key);
            request.onerror = () => rej();
            request.onsuccess = () => res();
        });
    }


    getItemAsync<TResult>(key: string) {

        return new Promise<TResult>(async (res, rej) => {

            const request = (await this.openReadAsync()).get(key);
            request.onerror = () => rej();
            request.onsuccess = () => {
                res(!request.result ? null : JSON.parse(request.result) as TResult);
            }
        });
    }

    keysAsync(): Promise<string[]> {
        throw new Error("Method not implemented.");
    }


    hasKeyAsync(key: string) {

        return new Promise<boolean>(async (res, rej) => {

            const request = (await this.openReadAsync()).getKey(key);
            request.onerror = () => rej();
            request.onsuccess = () => {
                res(request.result !== undefined);
            }
        });
    }


    removeItemAsync(key: string) {

        return new Promise<void>(async (res, rej) => {
            const request = (await this.openReadWriteAsync()).delete(key);
            request.onerror = () => rej();
            request.onsuccess = () => res();
        });
    }

    clearAsync() {

        return new Promise<void>(async (res, rej) => {
            const request = (await this.openReadWriteAsync()).clear();
            request.onerror = () => rej();
            request.onsuccess = () => res();
        });
    }

}