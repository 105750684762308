import { Button, Collapse, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import "./Section.scss";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Variant } from "@mui/material/styles/createTypography";

export interface ISectionProps {
    header?: ReactNode;
    children: ReactNode;
    canCollapse?: boolean;
    isCollapsed?: boolean;
    headerVariant?: Variant;
    className?: string;
}

export default function Section(props: ISectionProps) {

    const [isOpen, setIsOpen] = useState(!props.canCollapse || !props.isCollapsed);

    if (props.canCollapse) { 
         
        return <section className={"section collasable " + (isOpen ? "expanded " : "collapsed ") + (props.className ?? "")}>
            <Button color="primary" component="header" onClick={() => setIsOpen(!isOpen) }>
                <Typography color={isOpen ? "primary.main" : "secondary.contrastText"} variant={props.headerVariant ?? "h5"} >{props.header}</Typography>
                {isOpen ? <ExpandLess /> : <ExpandMore />}
            </Button>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                {isOpen && props.children}
            </Collapse>
        </section> 
    }

    return <section className={"section " + (props.className ?? "")}>
        {props.header && <Typography component="header" variant="h5">{props.header}</Typography>}
        {props.children}
    </section> 
}