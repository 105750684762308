

class UserSettings {

    get language() {
        return this.read<string>("language");
    }

    set language(value: string) {
        this.write("language", value);
    }

    protected read<T>(key: string, defaultValue?: T): T {
        const value = localStorage.getItem(key);
        if (!value)
            return defaultValue;
        return JSON.parse(value);
    }

    protected write(key: string, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }
}


export const userSettings = new UserSettings();