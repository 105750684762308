import { IAnalyticsService } from "./abstraction/IAnalyticsService";

declare global {
    function publytics(...args: any[]);
}

export class Publytics implements IAnalyticsService {

    private _loadAttempt = 0;

    constructor(options: Record<string, string>) {

        let tracking = options[document.location.hostname];
        if (!tracking) {
            tracking = "foo";
            console.warn(`Tracking not found for '${document.location.hostname}'`);
        }

        const script = document.createElement("script");
        script.dataset["domain"] = document.location.hostname + "/" + tracking;
        script.src = "https://api.publytics.net/js/script.manual.min.js";
        document.head.appendChild(script);
    }

    protected async ensureLoadedAsync() {

        while (!("publytics" in window)) {
            this._loadAttempt++;
            if (this._loadAttempt > 3)
                return false;
            await delayAsync(2000);
        }
        return true;
    }

    async pageView(url: string, title: string) {
        if (!await this.ensureLoadedAsync())
            return;
        publytics("pageview", { u: url })
    }

    async action(action: string, label: string) {

        if (!await this.ensureLoadedAsync())
            return;
        publytics("action", { props: { name: action, label } })
    }
}

function delayAsync(arg0: number) {
    return new Promise(res=>setTimeout(res, arg0))
}
