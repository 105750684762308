import { groupBy } from "../Utils";
import { dataService } from "../Services/DataService";
import { Fragment, MouseEvent, useEffect, useMemo, useState } from "react";
import { Guid, IEvent, ITicket } from "boxol-front";
import { Box, Button, Container, Divider, List, ListItem, ListItemButton, ListItemText, Paper, TextField, Typography } from "@mui/material";
import { formatDateTime, formatText } from "../Services/Localization";
import { useActions } from "../Hooks/ActionHook";
import { createContent } from "../Components/Content";
import { stringTable } from "../Services/StringTable";
import { useRefresh } from "../Hooks/RefreshContext";
import { SwitchSelector, SwitchSelectorItem } from '../Components/SwitchSelector';
import KeywordSearch, { IKeyword } from '../Components/KeywordSearch';
import { currentTime, truncateTime } from '../Services/DateTime';
import { useNetwork } from '../Hooks/NetworkHook';
import { YesOrNoActions, useMessageBox } from '../Components/MessageBox';
import { ReactComponent as TicketsIcon } from "../Assets/Tickets.svg";
import "./TicketList.scss";
import { SearchKeywordType } from "../Entites";
import { theme } from "../Services/Theme";
interface ITicketListViewProps {
    event: IEvent;
    tickets: ITicket[];
    onCancelOrder?: (ev: MouseEvent<HTMLButtonElement>, orderId: Guid) => Promise<void>;
}

interface ITicketsProps {
    tickets: ITicketListViewProps[];
}

function TicketListView(props: ITicketListViewProps) {

    const actions = useActions();

    const openDetails = () => actions.ticketsDetails(props.event?.id);

    const notConfirmed = props.tickets.filter(a => !a.isConfirmed).length;

    let status: string, statusClass: string;

    if (notConfirmed == 0) {
        status = formatText("confirmed");
        statusClass = "confirmed";
    }
    else {
        statusClass = "to-confirm";
        if (notConfirmed == props.tickets.length)
            status = formatText("to-confirm");
        else
            status = formatText("to-confirm-number", notConfirmed, props.tickets.length);
    }

    const categoryColor = dataService.getEventTagInfo(props.event)?.color ?? "#000";

    return <Fragment>
        <ListItem disablePadding >
            <ListItemButton onClick={openDetails}>
                <div className="main">
                    <ListItemText
                        primary={props.event.movie?.title ?? formatText(props.event.title)}
                        secondaryTypographyProps={{ component: "div" }}
                        secondary={
                            <Fragment>
                                <div>{formatDateTime(props.event.startTime)}</div>
                                <div className = "body3">{props.event.movie?.venue ?? props.event.place}</div>
                            </Fragment>
                        }
                    />
                    <span className="category" style={{backgroundColor: categoryColor}}/>
                </div>
                <footer>
                    <Typography className={"badge status " + statusClass}>{status}</Typography>
                    {dataService.canCancelOrder(props.event, props.tickets) && <Button variant="contained" className="badge" onClick={ev => { props.onCancelOrder(ev, props.tickets[0].orderId) }}>{formatText("cancel-reservation")}</Button>}
                </footer>
            </ListItemButton>

        </ListItem>
        <Divider />
    </Fragment>

}

type TicketType = "active" | "past";

function TicketList(props: ITicketsProps) {

    const refresh = useRefresh();

    const network = useNetwork();

    const [ticketType, setTicketType] = useState<TicketType>("active");

    const [tickets, setTickets] = useState(props.tickets);

    const [keywords, setKeywords] = useState<IKeyword<SearchKeywordType>[]>([]);

    const confirmCancelOrder = useMessageBox(
        formatText("cancel-your-prenotation"),
        ctx =>
        <Box>
            <Typography>{formatText("msg-confirm-cancel-prenotation")}</Typography>
                <TextField sx={{ mt: 1 }}
                    variant="standard"
                    value={ctx.getData("cancelNote", "")}
                    onChange={v => ctx.setData("cancelNote", v.target.value)}
                    label={formatText("note")} />
        </Box>,
        YesOrNoActions);

    const searchKeywords = useMemo(() => dataService.createSearchKeyword(props.tickets.map(a => a.event)), [props.tickets]);

    const lastUpdate = dataService.getLastTicketsUpdate().toLocaleString(stringTable.activeLanguage, {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit"
    }).replace(",", " ")

    const filterTickets = () => {

        let curTickets = props.tickets;
        const curTime = truncateTime(currentTime());

        if (ticketType == "past")
            curTickets = curTickets.filter(a => truncateTime(a.event.startTime).getTime() < curTime.getTime());
        else
            curTickets = curTickets.filter(a => truncateTime(a.event.startTime).getTime() >= curTime.getTime());

        if (keywords.length > 0) {
            var events = curTickets.map(a => a.event);
            events = dataService.filterEvents(events, keywords);
            curTickets = curTickets.filter(a => events.indexOf(a.event) != -1);
        }

        setTickets(curTickets);
    }

    useEffect(() => {
        filterTickets();
    }, [ticketType, keywords]);


    const cancelOrder = async (ev: MouseEvent<HTMLButtonElement>, orderId: Guid) => {

        ev.stopPropagation();
        ev.preventDefault();

        const result = await confirmCancelOrder.showAsync();

        const note = confirmCancelOrder.getData("cancelNote") as string;

        if (result == "yes") {
            network(async () => {
                if (await dataService.cancelOrderAsync(orderId, note)) {
                    refresh();
                }
            });

        }
    }

    return <Container>
        <div className="main">
            <header>
                <Typography variant="h5" className="title">{formatText("my-tickets-long")}</Typography>
                <SwitchSelector selectedColor={theme.current.palette.primaryColor} value={ticketType} onChanged={setTicketType}>
                    <SwitchSelectorItem key={0} value="active">{formatText("active")}</SwitchSelectorItem>
                    <SwitchSelectorItem key={1} value="past">{formatText("past")}</SwitchSelectorItem>
                </SwitchSelector>
            </header>

            <KeywordSearch
                onChanged={setKeywords}
                searchLabel={formatText("search")}
                source={searchKeywords} />

            <List>
                {tickets?.map(a =>

                    <TicketListView onCancelOrder={cancelOrder} key={a.event?.id} event={a.event!} tickets={a.tickets} />)
                }
            </List>
        </div>
        <Paper component="footer" elevation={3}>
            <div className="text">
                <Typography>
                    {formatText("msg-ticket-update")}
                </Typography>
                <Typography className="last-update" variant="subtitle2">{formatText("last-update", lastUpdate)}</Typography>
            </div>
            <Button onClick={refresh} variant="contained">{formatText("update-now")}</Button>
        </Paper>
        {confirmCancelOrder.component}
    </Container>
}

export const TicketListContent = createContent<ITicketsProps>({

    body: props => <TicketList {...props} />,
    title: "my-tickets",
    route: "/tickets",
    name: "my-tickets",
    icon: <TicketsIcon />,
    authorize: true,
    order: 0,
    async loadAsync(args, isRefresh) {

        if (!args.tickets || isRefresh) {

            let tickets = await dataService.getTicketsAsync(isRefresh);

            if (!isRefresh && tickets.some(a => !a.isConfirmed))
                tickets = await dataService.getTicketsAsync(true);

            let eventTickets = await Promise.all(groupBy(tickets, a => a.eventId, false).map(async a=> ({
                event: (await dataService.getEventByIdAsync(a.key))!,
                tickets: a.values
            })));

            eventTickets = eventTickets.filter(a => a.event != null);

            eventTickets.sort((a, b) =>
                new Date(a.event!.startTime).getTime() -
                new Date(b.event!.startTime).getTime());

            args.tickets = eventTickets;
        }

        return true;
    }
});