import { useEffect } from "react";
import { useActions } from "../Hooks/ActionHook";
import { useNetwork } from "../Hooks/NetworkHook";
import { TicketListContent } from "../Pages/TicketList";
import { dataService } from "../Services/DataService";


export function Reset() {

    const actions = useActions();
    const network = useNetwork();

    useEffect(() => {


        network(() => dataService.clearAllAsync());
        
        actions.loadContent(TicketListContent, null, true);

        setTimeout(() => {
            window.location.reload();
        });


    }, []);

    return null;
}