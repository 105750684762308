import { useEffect, useRef, useState } from "react";
import { LocationMap, MapView } from "@attractive/map-viewer";
import { useNetwork } from "../Hooks/NetworkHook";
import IconUp from "@attractive/map-viewer/Icons/ArrowUp.svg";
import "@attractive/map-viewer/index.css";
import { formatText } from "../Services/Localization";

export interface IMapViewerProps {
    src?: string;
    onReady: (mapView: MapView) => void;
    options?: MapView["options"];
}

export default function MapViewer(props: IMapViewerProps) {

    const mapViewRef = useRef<HTMLDivElement>();
    const network = useNetwork();

    const [mapView, setMapView] = useState<MapView>();
    const [map, setMap] = useState<LocationMap>();

    useEffect(() => {

        if (!props.src)
            return;

        const newMapView = new MapView({
            assets: {
                stagePointer: IconUp
            },
            selRectStyle: {
                strokeColor: "#01579b",
                fillColor: "rgba(24, 160, 251, 0.3)",
                strokeWidth: 1
            },
            showStagePointer: true,
            canZoom: true,
            canPan: true,
            canSelect: true,
            zoomControls: true,
            panControls: true,
            locales: {
                "msg-use-two-fingers": formatText("msg-use-two-fingers")
            },
            ...props.options
        });

        setMapView(newMapView);

        network(async () => {

            const state = await (await fetch(props.src)).text();
            setMap(new LocationMap(JSON.parse(state)));

        });

    }, [props.src]);

    useEffect(() => {

        if (!mapViewRef.current || !mapView)
            return;

        mapViewRef.current.innerHTML = "";

        mapView.attach(mapViewRef.current);

    }, [mapViewRef.current, mapView]);

    useEffect(() => {

        if (mapView?.container && map) {
            mapView.load(map);
            if (props.onReady)
                props.onReady(mapView);
        }

    }, [mapView, map, mapView?.container]);

    return <div className="map-view" ref={mapViewRef}></div>;
}