import { ThemeProvider } from '@mui/material';
import { HashRouter, Navigate, Route } from 'react-router-dom';
import { generateRoute } from '../Utils';
import { getContents } from '../Helpers/ContentManager';
import Operation from './Operation';
import { SetContent } from './SetContent';
import { Home } from '../Pages/Home';
import { Reset } from './Reset';
import { theme } from '../Services/Theme';

export function App() {

    const contents = getContents();


    const routes = contents.map(a =>
        <Route key={a.props.name} path={generateRoute(a.props.route)} element={<SetContent value={a}/>} />
    );

    routes.push(
        <Route key="root" path="/" element={<Navigate replace to={"/tickets"} />} />);

    routes.push(<Route key="root" path="/reset" element={<Reset />} />)

    return (
        <ThemeProvider theme={theme.mui}>
            <HashRouter>
                <Operation>
                    <Home routes={routes} />
                </Operation>
            </HashRouter>
        </ThemeProvider >
    );
}