import { HttpError, testPostMessage } from "boxol-front";
import { formatText } from "../Services/Localization";
import { IOperation, useOperation } from "./OperationContext";
import { useUser } from "./UserContext"; 

interface INetworkOptions {
    isLocal?: boolean;
    operation?: string;
}


export function useNetwork() {

    const operation = useOperation();

    const userContext = useUser();

    return async <T,>(action: () => Promise<T>, options ?: INetworkOptions) : Promise < T | null > => {

        let op: IOperation;
        while(true) {

            if (!options?.isLocal)
                op = operation.begin({ name: "Network operation" });
            if (options?.operation)
                testPostMessage("network.begin", options.operation);
            try {
                const result = await action();
                if (!options?.isLocal)
                    operation.end(op);
                return result;
            }
            catch (ex) {

                let msg: string;

                console.warn(ex);

                if (ex instanceof HttpError) {

                    if (ex.response.status == 401) {
                        userContext?.clear();
                    }
                    else
                        msg = "msg-server-error"
                }
                else
                    msg = "connection-error";

                if (!options?.isLocal)
                    operation.end(op);

                if (!msg)
                    return null;

                const result = await operation.showErrorAsync(formatText(msg), {
                    "retry": {
                        label: formatText("retry")
                    }
                });

                if (result != "retry")
                    return null;
            }
            finally {

                if (options?.operation)
                    testPostMessage("network.end", options.operation);
            }
        }
    }

}

