import { stringTable } from "../Services/StringTable";

stringTable.add({
	"acc-portal": "Portale Accreditati",
	"active": "Attivi",
	"back": "Prec.",
	"buy-your-ticket": "Acquista il tuo biglietto",
	"by-date": "Per data",
	"by-movie": "Per film",
	"by-place": "Per locale",
	"by-events": "Per eventi",
	"cancel-reservation": "Cancella Prenotazione",
	"cancel-your-prenotation": "Cancella la prenotazione",
	"code": "Biglietti",
	"confirmed": "Confermato",
	"connection-error": "Sei offline oppure la tua connessione dati è troppo debole",
	"date-time": "data ora",
	"date-time-value": "{0} alle {1}",
	"directions": "indicazioni",
	"director": "Regia",
	"category":"Categoria",
	"download": "Scarica",
	"duration": "Durata",
	"enter-all": "Entrata di gruppo",
	"events": "Eventi",
	"free": "gratuito",
	"free-seats-perc": "{0} liberi su {1}",
	"go-to": "Vai su",
	"language": "Secegli la lingua",
	"last-update": "Aggiornato il {0}",
	"location": "Luogo",
	"map": "Mappa",
	"msg-confirm-cancel-prenotation": "Sei sicuro di voler cancellare?",
	"msg-server-error": "Il server ha restituito un errore. Se il problema persiste, contattare il supporto tecnico.",
	"msg-ticket-update": "Non trovi il tuo biglietto? ",
	"my-boxol": "My Boxol",
	"my-orders": "i-miei-ordini",
	"my-tickets": "Biglietti",
	"my-tickets-long": "I miei biglietti",
	"next": "Succ.",
	"no": "no",
	"no-tickets": "Nessun biglietto disponibile",
	"note": "Note",
	"order-number": "Ordine n° {0}",
	"order-value": "Ordine n° ",
	"past": "Passati",
	"place": "sala",
	"price-from": "da {0}",
	"profile": "Profilo",
	"retry": "Riprova",
	"row": "fila",
	"search": "Ricerca...",
	"seat": "posto",
	"seats": "Posti",
	"sector": "settore",
	"show-in-maps": "mostra in maps",
	"tickets": "Biglietti",
	"tickets-count": "({0} biglietti)",
	"title": "Titolo",
	"to-confirm": "Da Confermare",
	"to-confirm-number": "Da Confermare ({0}/{1})",
	"update-now": "Aggiorna",
	"venue": "Locale",
	"yes": "si",
	"msg-content-not-found": "Contenuto non trovato oppure non sei autorizzato.",
	"msg-install-app": "Installa l'app sul tuo dispositivo {0}",
	"dont-show-again": "Non mostrare più",
	"step": "Passo {0}",
	"waiting-confirm": "Il tuo ordine è in attesa di conferma",
	"reserve-ticket": "Prenota il tuo biglietto",
	"msg-use-two-fingers": "Usare due dita per navigare sulla mappa",
	"sold-out": "sold out",
	"free-event": "Evento gratuito",
	"date": "Data",
	"time": "Ora",
	"ticket-num": "Biglietto {0} di {1}",
	"block": "Blocco"
}, "IT");