import { ReactElement, ReactNode, cloneElement } from "react";
import { analytics } from "../services/Catalog";

export interface IAnalyticsActionProps {

    action: string;

    label: string | number;

    children: {
        props: {
            onClick: (ev: React.MouseEvent) => void
        }
    }
}

export function AnalyticsAction(props: IAnalyticsActionProps) : ReactNode {

    const curOnClick = props.children.props.onClick;

    const onClick = (ev: React.MouseEvent) => {
        if (curOnClick)
            curOnClick(ev);
        analytics?.action(props.action, props.label);
    }

    return cloneElement(props.children as ReactElement, {
        onClick
    });
}