import {replaceArgs} from "./Localization";

type TableString = { (): string } | string;

export interface IStringTable {
    values: { [key: string]: TableString }
    lang: string;
}


class StringTable { 

    protected _tables: { [lang: string]: { [key: string]: TableString } };

    constructor() {

        this.activeLanguage = "EN";
        this._tables = {};
    }

    add(values: { [key: string]: TableString }, lang: string) {

        if (!(lang in this._tables))
            this._tables[lang] = {};

        for (const key in values) 
            this._tables[lang][key] = values[key];
    }

    get(key: string): string {

        if (key === undefined || key === null)
            return "";
        const table = this._tables[this.activeLanguage.toUpperCase()];
        const value = table ? table[key] : undefined;
        if (!value) {
            return replaceArgs(key, table as any);
        }
        if (typeof value === "function")
            return value();
        return value;
    }

    activeLanguage: string;
}

export const stringTable = new StringTable();