
interface ISize {
    width: number;
    height: number;
}

interface IGeoPoint {
    latitude: number;
    longitude: number;
}

export interface IStaticMapPin {
    center: IGeoPoint;
    icon?: any;
    name?: string;
}

export interface IStaticMapOptions {
    size?: ISize;
    center?: IGeoPoint;
    pins?: IStaticMapPin[];
    zoomLevel?: number;
}


class GeoMapService {

    openDirections(center: IGeoPoint) {
        window.open(`https://www.google.com/maps/dir/Current+Location/${center.latitude},${center.longitude}`);
    }

    openMaps(center: IGeoPoint) {
        window.open(`https://www.google.com/maps/search/?api=1&query=${center.latitude},${center.longitude}`);
    }

    staticMap(options: IStaticMapOptions): string {

        let result = "https://dev.virtualearth.net/REST/v1/Imagery/Map/Road";

        if (options.center) {
            result += "/" + options.center.latitude + "," + options.center.longitude;
            result += "/" + (options.zoomLevel ? options.zoomLevel : 15);
        }

        result += "?";

        if (options.size)
            result += "mapSize=" + options.size.width + "," + options.size.height + "&";

        if (options.pins) {
            options.pins.forEach(pin => {
                result += "pushpin=" + pin.center.latitude + "," + pin.center.longitude + ";";
                result += (pin.icon ? pin.icon : 5) + ";";
                if (pin.name)
                    result += encodeURIComponent(pin.name);

                result += "&";
            });
        }

        result += "format=PNG&key=" + encodeURIComponent(this.apiKey!);

        return result;
    }

    apiKey: string | undefined;
}


export const geoMapService = new GeoMapService();