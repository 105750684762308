import { Tab, Tabs, TabsTypeMap } from "@mui/material";
import { ReactElement, cloneElement, useEffect, useState } from "react";
import useAppBar from "../Hooks/AppBarContext";
import { formatText } from "../Services/Localization";
import { IContentProps } from "./Content";


export interface IContentListProps {
    children: ReactElement<IContentProps>[];
    navigation?: ReactElement;
    active?: string;
    onActiveChanged: (value: string) => void;
}


export function ContentList(props: IContentListProps) {

    const [active, setActive] = useState(props.active);

    const appBar = useAppBar();

    const navigation = props.navigation ?? <Tabs />;

    const validChildren = props.children.filter(a => a && typeof(a) == "object");

    const onChanged = (index: number) => {

        const value = validChildren[index].props.name;

        if (props.onActiveChanged)
            props.onActiveChanged(value);
        else
            setActive(value);
    }

    const curActive = props.onActiveChanged ? props.active : active;

    let nav: ReactElement;

    if (navigation.type == Tabs) {

        nav = cloneElement(navigation, {
            value: validChildren.findIndex(a => a.props.name == curActive),
            onChange: (_, v) => onChanged(v),
            variant: "fullWidth",
            children: validChildren.map((a, i) =>
                <Tab value={i} label={formatText(a.props.title)} />
            )
        } as Partial<TabsTypeMap["props"]>);
    }

    useEffect(() => {

        appBar.setState({ custom: nav });
    }, [curActive]);

    const content = validChildren.find(a => a.props.name == curActive);

    return <div className={"content-list " + content?.props.name ?? ""}>
        {validChildren.filter(a=> a != null).map(a => a.props.name == curActive ? cloneElement(a, { key: a.props.name }) : null)}
    </div>
}