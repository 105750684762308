
export function isAppleMobile() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function isAndroid() {
    return [
        'Android',
    ].includes(navigator.platform)
    || (navigator.userAgent.includes("Android") && "ontouchend" in document)
}


export function isStandalone() {
    return ("standalone" in navigator && navigator["standalone"] === true) ||
        ("clientInformation" in navigator && navigator["clientInformation"]["standalone"] === true) ||
        window.matchMedia("(display-mode: standalone)").matches;
}