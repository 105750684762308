import { ReactElement, cloneElement, createElement, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useActions } from "../Hooks/ActionHook";
import useAppBar, { IAppBarState } from "../Hooks/AppBarContext";
import { IContainerProps, useContentHost } from "../Hooks/ContentHostContext";
import { useUser } from "../Hooks/UserContext";
import { resolveContent } from "../Helpers/ContentManager";
import { useNetwork } from "../Hooks/NetworkHook";
import { Loading } from "./Loading";
import { RefreshContext } from "../Hooks/RefreshContext";
import { appSettings } from "../Services/AppSettings";
import { historyStates } from "../Services/HistoryMonitor";
import { NotFound } from "./NotFound";
import { analytics, testPostMessage } from "boxol-front";
import { Empty } from "./Empty";

export type ContentRef = ReactElement<IContentProps> | string;

export interface IContentTitle {
    title: string;
}

export interface IContentProps<TArgs extends object = any> {
    name: string;
    body: { (args: TArgs): ReactElement } | ReactElement;
    args?: TArgs;
    route: string;
    title?: string;
    hasHost?: boolean;
    icon?: ReactElement;
    container?: ReactElement<IContainerProps>;
    area?: string;
    authorize?: boolean;
    hasCustomAppBar?: boolean;
    isLoaded?: boolean;
    order?: number;
    parent?: ContentRef;
    loadAsync?(args: TArgs, isRefresh?: boolean): Promise<boolean>;
}

export function isContentProps<TArgs extends object>(obj: any): obj is IContentProps<TArgs> {

    return obj && typeof (obj) == "object" && typeof obj.title == "string" && typeof obj.route == "string";
}

export function isContentTitle(obj: any): obj is IContentTitle {

    return obj && typeof (obj) == "object" && typeof obj.title == "string";
}

export type LoadingState = "none" | "loading" | "loaded" | "error";

export function Content<TArgs extends object>(props: IContentProps<TArgs>): ReactElement | null {

    const [args, setArgs] = useState<TArgs>();

    const [loadingState, setLoadingState] = useState<LoadingState>("none");

    const { user } = useUser();

    const location = useLocation();

    const params = useParams();

    const contentHost = useContentHost();

    const appBar = useAppBar();

    const actions = useActions();

    const network = useNetwork();

    const navigate = useNavigate();

    const renderContainer = props.container != null;

    const curAgs = { ...props.args, ...args, ...params, ...location.state as any }

    const title = isContentTitle(curAgs) ? curAgs.title : props.title;

    const loadAsync = async (isRefresh: boolean) => {

        setLoadingState("loading");

        const newArgs = { ...curAgs, ...props.args } as TArgs;

        if (props.loadAsync) {

            testPostMessage("content.loading", props.name);

            const result = await network(() => props.loadAsync!(newArgs, isRefresh), { isLocal: !appSettings.globalLoading });

            testPostMessage("content.loaded", props.name);

            if (!result) {
                setLoadingState("error");
                if (props.area)  
                    contentHost.setContent(undefined, props.area);
                return;
            }

        }
        setArgs(newArgs);

        setLoadingState("loaded");

        appBar.setState({ loading: false });
    }

    useEffect(() => {

        if (renderContainer)
            return;

        if (props.authorize && (!user || user == "no-user")) {

            actions.login(location.pathname + location.hash);
            return;
        }

    }, [user]);

    useEffect(() => {

        if (renderContainer)
            return;

        if (!props.isLoaded)
            loadAsync(false);

        analytics.pageView(location.pathname, props.title);

    }, []);


    useEffect(() => {

        if (renderContainer || props.hasHost)
            return;

        const state: IAppBarState = {
            title: title,
            backAction: undefined
        };

        if (props.area && props.area != "main")
            state.backAction = () => {
                if (historyStates.length > 0)
                    navigate(-1);
                else {
                    contentHost.setContent(undefined, props.area);
                    actions.loadContent(contentHost.getContent("main"));
                }
            }

        else if (props.parent)
            state.backAction = () => actions.back(resolveContent(props.parent!)!);

        if (!props.hasCustomAppBar)
            state.custom = undefined;

        appBar.setState(state);

    }, [title]);

    useEffect(() => {


    }, [loadingState]);

    if (renderContainer)
        return cloneElement(props.container, { children: createElement(Content, { ...props, container: undefined }) })

    if (user && user != "no-user" && !user.isAuthorized && props.authorize)
        return <NotFound />;

    if (loadingState == "error")
        return <NotFound />;

    if (loadingState != "loaded")
        return appSettings.globalLoading ? <Empty /> : <Loading />;

    const body = typeof props.body == "function" ? props.body(curAgs) : props.body;

    return <RefreshContext.Provider value={() => loadAsync(true)}>
        {body}
    </RefreshContext.Provider>
}


export function createContent<TArgs extends object>(props: IContentProps<TArgs>) {
    const result = createElement<IContentProps<TArgs>>(Content, props);
    return result;
}