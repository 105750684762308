
export const historyStates = [];

export function monitorHistory() {

    const pushState = window.history.pushState.bind(window.history);

    window.history.pushState = (data, unused, url) => {
        pushState(data, unused, url);
        historyStates.push(data);
    }

    window.addEventListener("popstate", () => {
        historyStates.splice(historyStates.length - 1, 1);
    });
}