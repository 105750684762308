import { IMobileAppTheme } from "boxol-front";
import { preloadImageAsync } from "../Utils";
import { dataService } from "./DataService";
import { formatText } from "./Localization";
import { Theme as MuiTheme, ThemeOptions, createTheme } from "@mui/material";

class Theme {

    async loadAsync(theme: IMobileAppTheme) {

        this.current = theme;

        document.title = formatText(theme.name);

        let curMeta = document.querySelector('meta[name="theme-color"]') as HTMLMetaElement;

        if (!curMeta) {
            curMeta = document.createElement("meta");
            curMeta.name = "theme-color";
            document.head.appendChild(curMeta);
        }

        curMeta.content = theme.palette.themeColor;

        if (theme.header?.src) {
            const headerSrc = dataService.getAppResourceUrl(theme.header.src);
            await preloadImageAsync(headerSrc);
        }


        if (theme.icon) {
            const link = document.createElement("link");
            link.rel = "apple-touch-icon";
            link.setAttribute("sizes", theme.icon.sizes);
            link.href = dataService.getAppResourceUrl(theme.icon.src);
            document.head.appendChild(link);
        }


        const getColor = (value: string) => {
            if (!value || value.length == 0)
                return undefined;
            if (value.startsWith("#"))
                return value;
            return theme.palette[value];
        }

        const themeInfo: ThemeOptions = {
            typography: {
                fontFamily: "Mulish",

                h5: {
                    color: getColor(theme.palette.secondaryTextColor),
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "20px"
                },

                h4: {
                    color: getColor(theme.palette.secondaryTextColor)
                },

                body1: {
                    color: getColor(theme.palette.secondaryTextColor)
                }
            },
            components: {
                MuiBottomNavigation: {
                    styleOverrides: {
                        root: {
                            background: getColor(theme.bottomNav?.background)
                        }
                    }
                },
                MuiBottomNavigationAction: {
                    styleOverrides: {
                        root: {
                            color: getColor(theme.bottomNav?.text),
                            "&.Mui-selected": {
                                color: getColor(theme.bottomNav?.selected)
                            }
                        }
                    }
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            background: getColor(theme.appBar?.background),
                            color: getColor(theme.appBar?.text),
                        },

                    }
                },
                MuiTabs: {
                    styleOverrides: {
                        root: {
                            background: getColor(theme.tabs?.background),
                        },
                        indicator: {
                            background: getColor(theme.tabs?.selected)
                        }
                    }
                },
                MuiTab: {
                    styleOverrides: {
                        root: {
                            color: getColor(theme.tabs?.text),

                            "&.Mui-selected": {
                                color: getColor(theme.tabs?.selected)
                            }
                        }

                    }
                }
            },
            palette: {
                text: {
                    //secondary: getColor(curConfig.palette.secondaryTextColor)
                },
                primary: {
                    main: getColor(theme.palette.primaryColor),
                    contrastText: getColor(theme.palette.primaryTextColor)
                },
                secondary: {
                    main: getColor(theme.palette.secondaryColor),
                    contrastText: getColor(theme.palette.secondaryTextColor)
                }
            }
        };

        this.mui = createTheme(themeInfo);

        this.mui.components.MuiBottomNavigationAction.styleOverrides.selected = "#00f";

    }

    mui: MuiTheme;

    current: IMobileAppTheme;
}

export const theme = new Theme();