import { createContent } from "../Components/Content";
import { WebView } from "../Components/WebView";
import { useEffect } from "react";
import { delayAsync } from "../Utils";
import { formatText } from "../Services/Localization";
import { stringTable } from "../Services/StringTable";
import { ReactComponent as MyBoxolIcon } from "../Assets/MyBoxol.svg";
import { useUser } from "../Hooks/UserContext";
import "./Profile.scss";
import { appSettings } from "../Services/AppSettings";

interface IProfileProps {
    returnUrl?: string;
}

let checkLoop = true;

function Profile(props: IProfileProps) {

    const user = useUser();

    const myOrders = formatText("my-orders");

    const myOrdersRelUrl = stringTable.activeLanguage + "/" + myOrders + "?theme=mobileapp";

    const myOrdersUrl = appSettings.boxolWebRoot + myOrdersRelUrl;

    const loginUrl = appSettings.boxolWebRoot + stringTable.activeLanguage + "/account/login?theme=mobileapp&ReturnUrl=" + encodeURIComponent("/" + myOrdersRelUrl);

    const url = user.user && user.user != "no-user" ? myOrdersUrl : loginUrl;

    useEffect(() => {
        return () => {
            checkLoop = false;
        }
    }, []);

    const checkLoggedIn = async (iframe: HTMLIFrameElement) => {

        let waitLogin = false;

        checkLoop = true;

        while (checkLoop) {

            try {
                const baseUrl = iframe?.contentDocument?.baseURI;
                const url = baseUrl ? new URL(baseUrl) : null;
                if (waitLogin) {
                    if (url && url.pathname.indexOf(myOrders) != -1) {
                        waitLogin = false;
                        window.history.replaceState(null, null, "#" + props.returnUrl);
                        document.location.reload();
                    }
                }
                else {
                    if (url && url.pathname.indexOf("account/login") != -1) 
                        waitLogin = true;
                }
            }
            catch {

            }

            await delayAsync(500);
        }
    }


    const onLoaded = (frame: HTMLIFrameElement) => {
        if (props.returnUrl)
            checkLoggedIn(frame);
    }

    return <WebView timeout={5000} onLoaded={onLoaded} src={url}/>;
}

export const ProfileContent = createContent<IProfileProps>({

    body: props => <Profile {...props} />,
    title: "my-boxol",
    route: "/profile",
    name: "profile",
    icon: <MyBoxolIcon />,
    order: 2
});