import { ReactElement } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IContentProps } from "../Components/Content";
import { DebugOptionsContent } from "../Pages/DebugOptions";
import { EventDetailsContent } from "../Pages/EventDetails";
import { EventListContent, EventListFilter } from "../Pages/EventList";
import { ProfileContent } from "../Pages/Profile";
import { TicketListContent } from "../Pages/TicketList";
import { TicketsDetailsContent } from "../Pages/TicketsDetails";
import { dataService } from "../Services/DataService";
import { stringTable } from "../Services/StringTable";
import { userSettings } from "../Services/UserSettings";
import { generateRoute } from "../Utils";
import { useNetwork } from "./NetworkHook";

export function useActions() {

    const params = useParams()
    const navigate = useNavigate();
    const network = useNetwork();

    function loadContent<TArgs extends object>(content: ReactElement<IContentProps<TArgs>>, args?: Partial<TArgs>, replace = false) {
        navigate(generateRoute(content.props.route, {
            ...params,
            ...args
        }), {
            replace,
            state: {
                ...args
            }
        });
    }

    const result = {

        loadContent,

        changeLanguage(lang: string) {
            userSettings.language = lang;
            window.location.reload();
        },

        changeApp(appId: string) {
            window.location.href = `${process.env.PUBLIC_URL}/${stringTable.activeLanguage}/${appId}/#/`;
        },

        back(content: ReactElement<IContentProps>) {

            if (content)
                loadContent(content);
            else
                navigate(-1);
        },

        ticketsDetails(eventId: string, activeContent = "tickets-code", replace = false) {
            loadContent(TicketsDetailsContent, { eventId, activeContent }, replace);
        },

        ticketsCode(eventId: string) {
            this.ticketsDetails(eventId, "tickets-code");
        },

        ticketsMap(eventId: string) {
            this.ticketsDetails(eventId, "tickets-map");
        },

        ticketsDownload(eventId: string) {
            this.ticketsDetails(eventId, "tickets-download");
        },

        tickets() {
            loadContent(TicketListContent);
        },

        events(filter?: EventListFilter) {
            loadContent(EventListContent, {filter});
        },

        eventDetails(eventId: string) {
            loadContent(EventDetailsContent, { eventId });
        },

        profile() {
            loadContent(ProfileContent);
        },

        login(returnUrl: string) {
            loadContent(ProfileContent, { returnUrl });
        },

        debugOptions() {
            loadContent(DebugOptionsContent);
        },

        async logout() {
            await network(() => dataService.logoutAsync());
            window.location.reload();
        }
        
    }

    window["@actions"] = result;

    return result;
}

