import { IMobileAppSettings, apiClient, endPointMap, setAnalytics } from "boxol-front";
import { dataService } from "./DataService";
import { geoMapService } from "./GeoMapService";
import { stringTable } from "./StringTable";
import { userSettings } from "./UserSettings";
import { theme } from "./Theme";


export var appSettings: IMobileAppSettings;

export async function loadAppSettingsAsync() {

    const uriParse = /(\w\w)\/([^\/]+)\/#/.exec(window.location.href);

    if (!uriParse)
        return;

    const appId = uriParse[2];
    const lang = uriParse[1];

    stringTable.activeLanguage = userSettings.language ?? lang.toUpperCase();

    appSettings = {
        apiEndpoint: endPointMap[process.env.REACT_APP_ENV],
        instance: {
            appId,
            lang
        }
    } as IMobileAppSettings;

    apiClient.configure(appSettings);

    const manifest = document.querySelector("link[rel=manifest]") as HTMLLinkElement;
    manifest.href = apiClient.getManifestUrl("MobileApp");

    const result = await apiClient.getAppSettingsAsync("MobileApp");

    appSettings = { ...appSettings, ...result }

    setAnalytics(appSettings.analytics);

    apiClient.configure(appSettings);

    await dataService.configureAsync();

    await theme.loadAsync(appSettings.theme);

    geoMapService.apiKey = appSettings.bingApiKey; 

    if (localStorage.getItem("__debugMode") == "true") {
        appSettings.isDebug = true;
        appSettings.adminAuth = localStorage.getItem("__auth");
    }

}

