import { Container, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { dataService } from "../Services/DataService";
import { TicketListContent } from "./TicketList";
import { createContent } from "../Components/Content";
import { IBaseTicketsProps } from "./TicketsDetails";
import { Fragment } from "react";
import PdfIcon from "../Assets/PdfIcon.png"
import { distict } from "../Utils";
import { formatText } from "../Services/Localization";
import { apiClient } from "boxol-front";
import "./TicketsDownload.scss";

interface ITicketsDownloadProps extends IBaseTicketsProps {
}

function TicketsDownload(props: ITicketsDownloadProps) {

    const orders = distict(props.tickets.filter(a=> a.isConfirmed), a => a.orderId, a => ({
        orderId: a.orderId,
        orderPaymentId: a.orderPaymentId
    }));

    const download = (ticket: typeof orders[0]) => {
        window.open(apiClient.getPdfUrl(ticket.orderId, ticket.orderPaymentId), "download");
    }

    return <Container>
        <List>
            {orders.map(a => <Fragment key={a.orderPaymentId}>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => download(a)}>
                        <ListItemIcon>
                            <img height="49" src={PdfIcon} alt="pdf" />
                        </ListItemIcon>
                        <ListItemText className = "flex-fill" primary={formatText("order-number", a.orderPaymentId)} />
 
                    </ListItemButton>
                </ListItem>
                <Divider />
            </Fragment>)}
            {orders.length == 0 && <ListItem><ListItemText primary={formatText("waiting-confirm")}/></ListItem>}
        </List>

    </Container>;

}

export const TicketsDownloadContent = createContent<ITicketsDownloadProps>({

    body: args => <TicketsDownload {...args} />,
    route: "/tickets/:eventId/download",
    name: "tickets-download",
    title: "download",
    parent: TicketListContent,
    authorize: true,

    async loadAsync(args) {

        if (!args.tickets) {
            const tickets = await dataService.getTicketsAsync(false);
            args.tickets = tickets.filter(a => a.eventId == args.eventId);
        }

        if (!args.event)
            args.event = (await dataService.getEventByIdAsync(args.eventId))!;

        return true;
    }
});