import { Autocomplete, Box, Chip, InputAdornment, TextField } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useNetwork } from "../Hooks/NetworkHook";
import SearchIcon from '@mui/icons-material/Search';
import "./KeywordSearch.scss";

export interface IKeyword<TType> {
    text: string;
    type: TType;
    match?: string;
    value?: any;
    icon?: ReactElement;
}


export interface IKeywordSearchProps<TType> {
    source: IKeyword<TType>[] | { (): (IKeyword<TType>[] | Promise<IKeyword<TType>[]>) };
    onSelected?: (item: IKeyword<TType>) => boolean;
    onChanged?: (value: IKeyword<TType>[]) => void;
    value?: IKeyword<TType>[];
    searchLabel?: string;
}


export default function KeywordSearch<TType = any>(props: IKeywordSearchProps<TType>) {

    const [keywords, setKeywords] = useState<IKeyword<TType>[]>([]);

    const [selectedValues, setSelectedValues] = useState<IKeyword<TType>[]>(props.value ?? []);

    const [inputValue, setInputValue] = useState("");

    const [value, setValue] = useState<IKeyword<TType>>(null);

    const network = useNetwork();

    useEffect(() => {
        if (typeof props.source == "function") {
            const result = props.source();
            if (result instanceof Promise)
                network(async () => setKeywords(await result));
            else
                setKeywords(result);
        }
        else
            setKeywords(props.source);
    }, [props.source]);

    useEffect(() => {
        if (props.onChanged)
            props.onChanged(selectedValues);
    }, [selectedValues]);

    const getItenValue = (item: IKeyword<TType>) => item.value ?? item.text + "|" + item.type;

    const getSelectedIndex = (item: IKeyword<TType>) => {
        const itemValue = getItenValue(item);
        return selectedValues.findIndex(a => getItenValue(a) == itemValue);
    }

    const unselectItem = (item: IKeyword<TType>) => {
        const index = getSelectedIndex(item);
        if (index != -1) {
            const newValues = [...selectedValues];
            newValues.splice(index, 1);
            setSelectedValues(newValues);
        }

    }

    return <div className="keyword-search">
        <Autocomplete
            disablePortal
            options={keywords}
            inputValue={inputValue}
            value={value}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onChange={(_, newValue: IKeyword<TType>) => {

                setValue(newValue);

                if (newValue &&
                    getSelectedIndex(newValue) == -1 &&
                    (!props.onSelected || props.onSelected(newValue))) {

                    setSelectedValues([...selectedValues, newValue]);

                    setTimeout(() => {
                        setInputValue("");
                        setValue(null);
                        if ((document.activeElement as HTMLInputElement)?.blur)
                            (document.activeElement as HTMLInputElement).blur();
                    });
                  
                }
            }}
            renderInput={params => <TextField
                {...params}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    placeholder: props.searchLabel,

                }}
                variant="standard"

                fullWidth />}
            getOptionLabel={option => (option as IKeyword<TType>).text}

            renderOption={(props, option) => <Box component="li" {...props} key={option.type + option.text}>
                {option.icon}
                <span style={{marginLeft: "8px"}}>{option.text} </span>
            </Box>
            }
        />
        <div className= "selected">
            {selectedValues?.map(a =>
                <Chip label={a.text}
                    key={a.text + a.type}
                    avatar={a.icon}
                    variant="outlined"
                    onDelete={() => unselectItem(a)} />
            )}
        </div>
    </div>
}