import { ILocationMapState } from "@attractive/map-viewer";
import { Guid, IEvent, IEventFilter, IListResult, ILockSeatResult, IMapFileQuery, IReservationView, ILockSeatInfo, ISeatStateInfo, ISeatUpdate, IUserProfile, IReservedSeat, IDeliveryMethod, EventViewField, JsonDate, IResMonitorData, ILockBestSeatInfo, ILockBestSeatResult, ITicketFilter, ITicket, IVenue, IVenueFilter, IUserView, IValidationResult, ICheckCartResult, ITimeSerieInfo, ITimeSerieFilter, ITimeSerieFieldValue, ITimeSerieValue, ICheckoutInfo, IAdvertize, IAdvertizeFilter, IPaymentInfo, PaymentStatus, IValidateRegistrationLevelResult, IBoxolUser, IRegisterUserDetails, IRegisterUserData, IRegisterUserResult } from "../Entities";
import { BaseApiClient } from "./BaseApiClient";
import { IMobileAppSettings, IWebAppSettings } from "../config";


type AppSettingsMap = {
    "WebSite": IWebAppSettings,
    "MobileApp": IMobileAppSettings
}

class BoxolApiClient extends BaseApiClient {

    constructor() {
        super();
    }

    //Checkout

    async checkoutInfoAsync(email: string, orderRef:string) {
        return await this.requestJsonAsync<ICheckoutInfo>(`${this.apiPrefix}checkout/info`, "GET", {
            useServerCache: false,
            useClientCache: false,
            query: {
                email,
                orderRef
            }
        });
    }

    async emitTicketsAsync(email: string, orderRef: string, singleTicket: boolean = false) {
        return await this.requestJsonAsync<boolean>(`${this.apiPrefix}checkout/emit`, "POST", {
            useServerCache: false,
            useClientCache: false,
            query: {
                email,
                orderRef,
                singleTicket
            }
        });
    }

    async createPaymentUrlAsync(orderRef: string) {
        return await this.requestJsonAsync<IPaymentInfo>(`${this.apiPrefix}checkout/payment`, "POST", {
            useServerCache: false,
            useClientCache: false,
            query: {
                orderRef
            }
        });
    }

    async getPaymentStatusAsync(paymentId: string) {
        return await this.requestJsonAsync<PaymentStatus>(`${this.apiPrefix}checkout/payment-status/${paymentId}`, "GET", {
            useServerCache: false,
            useClientCache: false
        });
    }

    checkoutDownloadPdfUrl(email: string, orderRef: string, connectionId?: number) {

        return this.requestUrl(`${this.apiPrefix}checkout/download`, {
            query: {
                connectionId,
                email,
                orderRef
            }
        });

    }

    //Event

    async getSeatStateAsync(eventId: Guid, useCache = true) {

        return await this.requestJsonAsync<ISeatStateInfo>(`${this.apiPrefix}event/${eventId}/state`, "GET", {
            useServerCache: useCache,
            useClientCache: false
        });
    }
    async getSeatStateCompactAsync(eventId: Guid, useCache = true) {

        return await this.requestJsonAsync<Record<string, string[]>>(`${this.apiPrefix}event/${eventId}/state-compact`, "GET", {
            useServerCache: useCache,
            useClientCache: false
        });
    }

    async getEventsAsync(filter: Partial<IEventFilter>, useCache = true) {

        return await this.requestJsonAsync<IListResult<IEvent>>(`${this.apiPrefix}event`, "GET", {
            query: filter,
            useServerCache: useCache,
            useClientCache: false
        });
    }

    async checkMap4Async(webId: number, useCache = true) {

        return await this.requestJsonAsync<boolean>(`${this.apiPrefix}event/${webId}/check-map4`, "GET", {
            useServerCache: useCache,
            useClientCache: false
        });
    }

    async getAdvertizesAsync(filter: Partial<IAdvertizeFilter>, useCache = true) {

        return await this.requestJsonAsync<IListResult<IAdvertize>>(`${this.apiPrefix}adv`, "GET", {
            query: filter,
            useServerCache: useCache,
            useClientCache: false
        });
    }

    //Tickets


    async getTicketsAsync(filter: Partial<ITicketFilter>, useCache = true) {
        return await this.requestJsonAsync<IListResult<ITicket>>(`${this.apiPrefix}ticket`, "GET", {
            query: filter,
            useServerCache: useCache,
            useClientCache: false
        });
    }


    async getDeliveryMethodsAsync() {

        return await this.requestJsonAsync<IDeliveryMethod[]>(`${this.apiPrefix}ticket/delivery`, "GET");
    }


    getPdfUrl(orderId: Guid, orderCode: string) {
        return `${this.endpoint}${this.apiPrefix}ticket/${orderId}/pdf/${orderCode}.pdf`;
    }

    async completeRegistrationLinkAsync(email: string, orderCode?: string) {

        return await this.requestJsonAsync<boolean>(`${this.apiPrefix}account/complete-registration`, "POST", {
            query: {
                email,
                orderCode
            }
        });
    }

    //Venues

    async getVenuesAsync(filter: Partial<IVenueFilter>, useCache = true) {

        return await this.requestJsonAsync<IListResult<IVenue>>(`${this.apiPrefix}venue`, "GET", {
            useServerCache: useCache,
            useClientCache: false
        });
    }

    async getVenueMapAsync(filter: Partial<IEventFilter>, useCache = true) {

        return await this.requestJsonAsync<Record<string, string>[]>(`${this.apiPrefix}event/venue-map`, "GET", {
            query: filter,
            useServerCache: useCache,
            useClientCache: false
        });
    }

    async openMapAsync(query: IMapFileQuery) {

        return await this.requestJsonAsync<ILocationMapState>(`${this.apiPrefix}venue/map`, "GET", {
            query
        });
    }

    async getUserAsync(fullProfile = false) {
         
        return await this.requestJsonAsync<IUserProfile>(`${this.apiPrefix}app/user`, "GET", {
            query: {
                fullProfile
            }
        });
    }

    //Reservations




    getCancelReservationUrl(token: string, eventId: Guid, reason?: string) {

        return `${this.endpoint}${this.apiPrefix}reservation/cancel/${eventId}/${token}?reason=${encodeURIComponent(reason)}`
    }

    getDeviceVisibilityUrl(token: string, eventId: Guid, visible: boolean) {

        return `${this.endpoint}${this.apiPrefix}reservation/device-visible/${eventId}/${token}/${visible}`
    }

    async checkCartAsync(eventId: Guid, cartId: Guid) {

        return await this.requestJsonAsync<ICheckCartResult>(`${this.apiPrefix}reservation/check-cart/${eventId}/${cartId}`, "GET");
    }

    async deleteCartAsync(token: string, eventId: Guid, cartId: Guid) {

        return await this.requestJsonAsync<boolean>(`${this.apiPrefix}reservation/cart/${eventId}/${cartId}/${token}`, "DELETE");
    }

    async cancelReservationAsync(token: string, eventId: Guid, reason?: string) {

        return await this.requestJsonAsync<boolean>(`${this.apiPrefix}reservation/${eventId}/${token}`, "DELETE", {
            query: {
                reason
            }
        });
    }

    async updateSeatAsync(token: string, eventId: Guid, seat: IReservedSeat, update: ISeatUpdate) {

        return await this.requestJsonAsync<boolean>(`${this.apiPrefix}reservation/update/${eventId}/${token}`, "PATCH", {
            data: {
                ...update,
                seat
            },
            useCaptcha: true
        });
    }

    async lockBestSeatAsync(token: string, eventId: Guid, data: ILockBestSeatInfo) {

        return await this.requestJsonAsync<ILockBestSeatResult>(`${this.apiPrefix}reservation/lock-best/${eventId}/${token ?? ""}`, "POST", {
            data,
            useCaptcha: !this._appSettings.adminAuth
        });
    }

    async lockSeatAsync(token: string, eventId: Guid, data: ILockSeatInfo) {

        return await this.requestJsonAsync<string>(`${this.apiPrefix}reservation/lock/${eventId}/${token ?? ""}`, "POST", {
            data,
            isText: true,
            useCaptcha: true
        });
    }

    async unlockSeatAsync(token: string, eventId: Guid, data: ILockSeatInfo) {

        return await this.requestJsonAsync<ILockSeatResult>(`${this.apiPrefix}reservation/unlock/${eventId}/${token}`, "POST", {
            data,
            useCaptcha: true
        });
    }

    async getReservationAsync(token: string, eventId: Guid) {

        return await this.requestJsonAsync<IReservationView>(`${this.apiPrefix}reservation/${eventId}/${token}`, "GET");
    }

    async getTokenExpireTimeAsync(token: string) {
        return await this.requestJsonAsync<JsonDate>(`${this.apiPrefix}reservation/expire/${token}`, "GET");
    }

    async clearReservationsAsync(eventId: Guid) {
       
        return await this.requestJsonAsync<IResMonitorData[]>(`${this.apiPrefix}reservation/clear/${eventId}`, "DELETE", {

        });
    }


    async validateReservationAsync(token: string, eventId: Guid) {

        return await this.requestJsonAsync<IValidationResult>(`${this.apiPrefix}reservation/validate/${eventId}/${token}`, "POST");
    }

    //Resources

    async getAppSettingsAsync<T extends keyof AppSettingsMap>(settingsType: T) {

        const result = await this.requestJsonAsync<AppSettingsMap[T]>(`${this.appPrefix}/${settingsType}/settings`, "GET", {
            useServerCache: true,
            useClientCache: true
        });

        this.configure(result);

        return result;
    }

    getManifestUrl(appType: keyof AppSettingsMap) {

        return `${this.endpoint}${this.appPrefix}/${appType}/manifest`;
    }

    getResourceUrl(name: string) {

        return `${this.endpoint}${this.appPrefix}/resource/${name}`
    }

    //Reports

    async getTimeSeriesAsync() {
        return await this.requestJsonAsync<ITimeSerieInfo[]>(`${this.apiPrefix}report`, "GET");
    }

    async getTimeSerieValuesAsync(name: string, filter: ITimeSerieFilter) {
        return await this.requestJsonAsync<ITimeSerieValue[]>(`${this.apiPrefix}report/${name}`, "GET", {
            query: filter
        });
    }

    async getTimeSerieFieldAsync(name: string, fieldName: string, filter?: ITimeSerieFilter, query?: string, count?: number) {
        return await this.requestJsonAsync<ITimeSerieFieldValue[]>(`${this.apiPrefix}report/${name}/field/${fieldName}`, "GET", {
            query: {
                ...filter,
                count,
                query
            }
        });
    }

    //Account

    async sendOtpAsync(phoneNumber: string) {
        return await this.requestJsonAsync<string>(`${this.apiPrefix}account/send-otp`, "POST", {
            query: {
                phoneNumber
            },
            isText: true
        });
    }

    async verifyOtpAsync(code: string) {
        return await this.requestJsonAsync<boolean>(`${this.apiPrefix}account/verify-otp`, "POST", {
            query: {
                code
            },

        });
    }

    async registerUpdateAsync(data: IRegisterUserData, eventWebId?: number) {
        return await this.requestJsonAsync<IRegisterUserResult>(`${this.apiPrefix}account/register`, "PATCH", {
            data,
            query: {
                eventWebId
            }
        });
    }


    async registerAsync(data: IRegisterUserData, eventWebId?: number) {
        return await this.requestJsonAsync<IRegisterUserResult>(`${this.apiPrefix}account/register`, "POST", {
            data,
            query: {
                eventWebId
            }
        });
    }

    async validateRegistrationLevelAsync(eventWebId?: number) {
        return await this.requestJsonAsync<IValidateRegistrationLevelResult>(`${this.apiPrefix}account/validate-level`, "GET", {
            query: {
                eventWebId
            }
        });
    }

    async getRegistrationDetailsAsync(eventWebId?: string, forceLevel?: number) {
        return await this.requestJsonAsync<IRegisterUserDetails>(`${this.apiPrefix}account/register-details`, "GET", {
            query: {
                eventWebId,
                forceLevel
            }
        });
    }

    async googleLoginAsync(token: string) {
        return await this.requestJsonAsync<IBoxolUser>(`${this.apiPrefix}account/login/google`, "POST", {
            query: {
                token
            }
        });
    }

    async loginAsync(username: string, password: string, rememberMe: boolean) {
        return await this.requestJsonAsync<IBoxolUser>(`${this.apiPrefix}account/login`, "POST", {
            query: {
                username, 
                password,
                rememberMe
            }
        });
    }

    async loginPhoneAsync(phoneNumber: string) {
        return await this.requestJsonAsync<string>(`${this.apiPrefix}account/login/phone`, "POST", {
            query: {
                phoneNumber
            },
            isText: true    
        });
    }


    async loginPhoneVaidateAsync(code: string, token: string) {
        return await this.requestJsonAsync<IBoxolUser>(`${this.apiPrefix}account/login/phone/${code}`, "POST", {
            query: {
                token
            }
        });
    }

    //Admin

    async getUsersAsync() {
        return await this.requestJsonAsync<IUserView[]>(`${this.apiPrefix}admin/users`, "GET");
    }

    async getReservationStatusAsync() {
        return await this.requestJsonAsync<IResMonitorData[]>(`${this.apiPrefix}admin/res-monitor`, "GET");
    }

    async clearCacheAsync() {
        return await this.requestJsonAsync<string[]>(`${this.apiPrefix}admin/clear-cache`, "DELETE");
    }


    async getServerConfigAsync(appId: string) {
        return await this.requestJsonAsync<object>(`en/${appId}/api/latest/admin/server-config`, "GET");
    }

    async listAppsAsync() {
        return await this.requestJsonAsync<string[]>(`${this.apiPrefix}admin/apps`, "GET");
    }

    //Client 

    override fillHeaders(headers: Record<string, string>) {

        if (this._appSettings.mockUserId)
            headers["X-Mock-UserId"] = this._appSettings.mockUserId;

        if (this.mockUserId)
            headers["X-Mock-UserId"] = this.mockUserId;

        if (this._appSettings.adminAuth)
            headers["X-Token"] = this._appSettings.adminAuth;
    }

    protected get appPrefix() {
        return `${this.instance.lang}/${this.instance.appId}`;
    }

    protected get apiPrefix() {
        return `${this.appPrefix}/api/latest/`;
    }

    get instance() {
        return this._appSettings.instance;
    }

    override get endpoint() {
        return this._appSettings.apiEndpoint;
    }

    mockUserId: Guid;

}

export const apiClient = new BoxolApiClient();