import { createContext, useContext } from "react";
import { IUserProfile } from "boxol-front";


export interface IUserContext {
    clear();
    user: IUserProfile | "no-user";
}

export const UserContext = createContext<IUserContext>({ user: undefined, clear: () => { } });

export function useUser() {
    return useContext(UserContext);
}